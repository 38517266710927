
import React, { useEffect, useState, useRef } from "react";

import MuiDaterange from "../../elements/Mui/mui_data_range.js";

import { PrimeReactProvider } from 'primereact/api';
import { Box, Typography, Grid, Select, MenuItem, Button, collapseClasses, Checkbox } from "@mui/material";
import { ReceiptLongOutlined } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import '../../styles/brandingStyles/branding.css';
import '../../styles/pageStyles/dashboard.css';
import ClearIcon from '@mui/icons-material/Clear';
import ShareBalIcon from '../../assets/dashboard/share.png';
import SubsBalIcon from '../../assets/dashboard/subscription.png';
import loanBalIcon from '../../assets/dashboard/loan.png';
import SdBalIcon from '../../assets/dashboard/sd.png';
import BonusBalIcon from '../../assets/dashboard/Bonus.png';
import LastWithdrawnIcon from '../../assets/dashboard/lastWithdrawn.png';
import LastDepositIcon from '../../assets/dashboard/lastDeposit.png';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Sidebar from "../../components/sidebar";
import { useDispatch, useSelector } from 'react-redux';
import { updateTrans, updateLoansel } from "../../redux/toggleBarReducer.js"
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from "primereact/inputtext";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import ReactDOMServer from 'react-dom/server';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
// const TableContent=()=>{
//     return(<Box id="memberdetails">
//     
// }
import logo from "../../assets/oasis_logo.png";

import Header from '../../components/header';

import html2canvas from "html2canvas"
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { updateKeyIn } from "../../redux/toggleBarReducer";
import CustomAxios from "../../utils/CustomAxios";
export const MemberTransaction = ({key}) => {
    const componentExport = useRef()
    const KeyIn = useSelector(state=>state.toggleBar.key);
    const memberid = useSelector(state=>state.toggleBar.memberid);
    console.log(KeyIn)
    const dispatch = useDispatch()
    const [memberBalance, setMemberBalance] = useState([]);
    const [transDetails, setTransDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState('by_date');
    const [transType, setTransType] = useState('share_transactions')
    const [dates, setDates] = useState(null);
    const [loanDetails,setLoanDetails]=useState([])
    const [sdDetails,setSdDetails]=useState([])
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [payloadDr, setPayloadDr] = useState(true);
    const [selected,setSelected]=useState([])
    const [checked, setChecked] = useState(false); 
    console.log(memberid,KeyIn)
    const onRowSelect = (event, param) => {
        console.log(event, param)
        if (param=="loan"){
        let loantrans=transDetails
        let loan_data=loantrans.data
        let filtered=loan_data.filter(item=>item.loanno==event.data.loanid)
        console.log(filtered)
        setSelected(filtered)
    dispatch(updateLoansel(filtered))}
        else if (param=="sd"){
            let loantrans=transDetails
            let loan_data=loantrans.data
            let filtered=loan_data.filter(item=>item.loanno==event.data.depositid)
            console.log(filtered)
            setSelected(filtered)}
     };
     const onRowUnselect = (event) => {
        setSelected([])
    };
    const clearDefaults = () =>{
        setLoanDetails([])
        setSdDetails([])
        setSelectedProduct(null)
        setSelected([])
        setTransDetails([])
    }
    useEffect(() => { 
        if(KeyIn==true) {
             clearDefaults()
            setLoading(true)
            const payload1 = {
                memberid: memberid,
                transtype:"share_sub_yearly",
                year:2023
            }
            CustomAxios.post(`oasis/member_dashboard/`, payload1)
              .then(response => {
                console.log('API Response:', response);
                const responseData = response.data;
                setMemberBalance(responseData);
                setLoading(false)
                })
                .catch(error => {
                    console.error('Error:', error);
                    setLoading(false)
                });

                
            const payload = {
            memberid: memberid,
            transtype: transType,
            year: "",
            dr:["2023-1-1", "2024-12-31"]
        };
        const new_payload = {
            memberid: memberid,
            transtype: transType,
            year: "",
        };
        CustomAxios.post(`oasis/transaction/`,transType=="share_transactions" || transType=="subscription_transactions" ? payload:new_payload)
            .then(response => {
                console.log('API Response:', response);
                const responseData = response.data;
                console.log("ResponseData", responseData);
                setSelected([])
                setSdDetails(response.data.specificDeposit)
                setLoanDetails(response.data.loan)
                setTransDetails(responseData);
                setLoading(false)
            })
            .catch(error => {
                console.error('Error:', error);
                setLoading(false)
            });
            dispatch(updateKeyIn(false))
        }
    }, [memberid,KeyIn])

    const handleTransType = (transType) => {
        console.log("transtype------", transType);
        setLoading(true);
        clearDefaults()
        setTransType(transType);
        const payload1 = {
            memberid: memberid,
            transtype:"share_sub_yearly",
            year:2023
        }
        CustomAxios.post(`oasis/member_dashboard/`, payload1)
          .then(response => {
            console.log('API Response:', response);
            const responseData = response.data;
            setMemberBalance(responseData);
            setLoading(false)
            })
            .catch(error => {
                console.error('Error:', error);
                setLoading(false)
            });
        let dateFormate = ["2023-1-1", "2024-12-31"]
        const payload = {
            memberid: memberid,
            transtype: transType,
            year: "",
        }
        
        if (dates && dates.length > 0) {
            dateFormate = dates.map(item => {
                const originalDate = new Date(item);
                const formattedDate = `${originalDate.getFullYear()}-${originalDate.getMonth() + 1}-${originalDate.getDate()}`;
                const [day, month, year] = formattedDate.split("/")
                return formattedDate
            })
        }
        const payloadDate = {
            memberid: memberid,
            transtype: transType,
            year: "",
            dr: dateFormate,
        };

        CustomAxios.post(`oasis/transaction/`, transType=="share_transactions" || transType=="subscription_transactions" ? payloadDate: payload )
            .then(response => {
                console.log('API Response inside dropdown function------------:', response);
                const responseData = response.data;
                console.log("ResponseData-----------", responseData);
                setLoanDetails(response.data.loan)
                setSdDetails(response.data.specificDeposit)
                setTransDetails(responseData);
                // setDates(null)
                setPayloadDr(false)
            })
            .catch(error => {
                console.error('Error:', error);
            });
            setLoading(false);

    }

    const handleChange = (event) => { 
        setChecked(event.target.checked); 
    };

    // useEffect(() => {
    //     if (dates !== null) {
    //         setPayloadDr(true)
    //         if (!dates.includes(null)) {
    //             handleTransType(transType)
    //         }
    //     }
    // }, [dates])
    useEffect(() => {
        if(transType){
            handleTransType(transType)
        }
    }, [transType])

    const handleDropdownChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const dateOfJoinBody=(rowData)=>{
        //console.log(rowData)

        const originalDate = new Date(rowData.transdate);
            const dd = String(originalDate.getUTCDate()).padStart(2, '0');
            const mm = String(originalDate.getUTCMonth() + 1).padStart(2, '0'); // January is 0!
            const yy = String(originalDate.getUTCFullYear());
            
            return `${dd}-${mm}-${yy}`;
    }
    const loanStartDate=(rowData)=>{
        //console.log(rowData)

        const originalDate = new Date(rowData.loanstartdate);
        const dd = String(originalDate.getUTCDate()).padStart(2, '0');
        const mm = String(originalDate.getUTCMonth() + 1).padStart(2, '0'); // January is 0!
        const yy = String(originalDate.getUTCFullYear());
        
        return `${dd}-${mm}-${yy}`;
    }
    const loanEndDate=(rowData)=>{
        //console.log(rowData)

        const originalDate = new Date(rowData.loanexpirydate);
        const dd = String(originalDate.getUTCDate()).padStart(2, '0');
            const mm = String(originalDate.getUTCMonth() + 1).padStart(2, '0'); // January is 0!
            const yy = String(originalDate.getUTCFullYear());
            
            return `${dd}-${mm}-${yy}`;
    }
    const sdStartDate=(rowData)=>{
        //console.log(rowData)

        const originalDate = new Date(rowData.depositdate);
        const dd = String(originalDate.getUTCDate()).padStart(2, '0');
        const mm = String(originalDate.getUTCMonth() + 1).padStart(2, '0'); // January is 0!
        const yy = String(originalDate.getUTCFullYear());
        
        return `${dd}-${mm}-${yy}`;
    }
    const sdEndDate=(rowData)=>{
        //console.log(rowData)

        const originalDate = new Date(rowData.expirydate);
        const dd = String(originalDate.getUTCDate()).padStart(2, '0');
        const mm = String(originalDate.getUTCMonth() + 1).padStart(2, '0'); // January is 0!
        const yy = String(originalDate.getUTCFullYear());
        
        return `${dd}-${mm}-${yy}`;
    }
    const numFormatpayment_in = (rowData) =>{
        //console.log(rowData)
         if(rowData.payment_in){
            return parseInt(rowData.payment_in).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
         }
    }
    const numFormatpayment_out = (rowData) =>{
         if(rowData.payment_out){
            return parseInt(rowData.payment_out).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
         }
    }
    const numFormatbalance = (rowData) =>{
         if(rowData.balance){
            return parseInt( rowData.balance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
         }
    }
    const handleFetchAndPrintReceipt = async () => {
        try {
            let payload
            if (selected.length>0){
            payload = {
                transtype:transType=="share_transactions"?"share":transType=="subscription_transactions"?"subscription":"loan_transactions", 
                loanno:selected[0].loanno
            };}
            else{
            payload = {
                    transtype:transType=="share_transactions"?"share":transType=="subscription_transactions"?"subscription":"loan_transactions",
                    withNotes: checked
                }; 
            }
            // URL to your Django view that generates the PDF
            const receiptUrl = `oasis/member_ledger_statement/${memberid}/`;

            // Fetch the PDF receipt
            const response = await CustomAxios.post(receiptUrl,payload,{ responseType: 'blob' });
            if (response.data) {
                // Create a URL for the PDF blob
                console.log("Created", response.data)
                const fileURL = URL.createObjectURL(response.data);

                // Open the PDF in a new window/tab
                const win = window.open(fileURL, '_blank');

                // Optionally, automatically trigger the print dialog in the new window
                win.onload = () => {
                    win.focus();
                    win.print();
                };
            }
        } catch (error) {
            console.error("Error fetching the receipt: ", error);
        }
    };
    const handleTransactionType=(e, where)=>{
        e.preventDefault()
        if (where=="share"){
            setTransType("share_transactions")
            dispatch(updateTrans("share_transactions"))
        }
        if (where=="subs"){
            setTransType("subscription_transactions")
            dispatch(updateTrans("subscription_transactions"))
        }
        if (where=="loan"){
            setTransType("loan_transactions")
            dispatch(updateTrans("loan_transactions"))
        }
        if (where=="sd"){
            setTransType("specific_transactions") 
            dispatch(updateTrans("specific_transactions"))
        }
        if (where=="bonus"){
            setTransType("bonus_transactions") 
            dispatch(updateTrans("bonus_transactions"))
        }
        

    }
    console.log(sdDetails)
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const transtypeArr = [{label:"Share Balance",value:"share_transactions"},{ label:"Subscription Balance",value:"subscription_transactions"}, {label:"Loan Balance",value:"loan_transactions"}, {label:"Specific Deposit",value:"specific_transactions"}, {label:"Bonus",value:"bonus_transactions"}]
    return (
        <PrimeReactProvider sx={{width:"100%"}}>
            <Box >
                <Box  >
                    <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
                        {/* <Box sx={{ display: "flex", justifyContent:"space-between" }}>
                        <Box sx={{ display: "flex", gap: "8px" }}>
                            <Box>
                                <Dropdown value={transType} onChange={(e) => setTransType(e.value)} style={{ width: "250px", height: "48px", display: "flex", alignItems: "center", fontSize: "16px", lineHeight: "24px", fontWeight: "400", fontFamily: "inter" }} options={transtypeArr}
                                    placeholder="Select Transtype" />
                            </Box>
                            <Box className="p-input-icon-left">
                                <i className="pi pi-search" style={{ color: "#CBD5E1" }} />
                                <InputText style={{ height: "48px", width: "250px" }} placeholder='Keyword Search' ></InputText>
                            </Box>
                            <Box id="dropdown">
                                <Select style={{ width: "250px", height: "48px", fontFamily: "inter", fontSize: "16px" }} value={selectedOption} onChange={handleDropdownChange}>
                                    <MenuItem value="overall_transactions" onClick={() => handleTransType(transType)}>Show All Transaction</MenuItem>
                                    <MenuItem value="by_date">By Date</MenuItem>
                                </Select>
                            </Box>
                            
                            </Box>
                        </Box> */}
                        <Box sx={{ display: "flex", gap: "8px",justifyContent:"space-between",alignItems:"center"}} >
                        <Box className='scroll' sx={{ display: "flex", height: "68px",overflowX:"auto"}}>
                            <Box sx={{ display: "flex", alignItems: "center",padding:"5px",flexShrink:0 , border:transType=="share_transactions"&&"1px solid #288d2c",cursor:"pointer"}} onClick={(e)=>handleTransactionType(e, "share")}>
                                <Box component='img' sx={{ width: "36px", height: "36px" }} src={ShareBalIcon} />
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Box className="pgh-heading" >Share Balance</Box>
                                    <Box className="fn-h1">
                                        RM {loading==true?<i className="pi pi-spin pi-spinner" style={{fontSize:"30px",marginLeft:"20px"}}/>:memberBalance.share?parseInt(memberBalance.share).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):(0).toFixed(2)}
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center",flexShrink:0  , border:transType=="subscription_transactions"&&"1px solid #288d2c",cursor:"pointer"}} onClick={(e)=>handleTransactionType(e,"subs")}>
                                <Box component='img' sx={{ width: "36px", height: "36px" }} src={SubsBalIcon} />
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Box className="pgh-heading" >Subscription Balance</Box>
                                    <Box className="fn-h1">
                                        RM {loading==true?<i className="pi pi-spin pi-spinner" style={{fontSize:"30px",marginLeft:"20px"}}/>:memberBalance.subscription?parseInt(memberBalance.subscription).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):(0).toFixed(2)}
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center",flexShrink:0}}>
                                <Box component='img' sx={{ width: "36px", height: "36px" }} src={SubsBalIcon} />
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Box className="pgh-heading" >Total Balance</Box>
                                    <Box className="fn-h1">
                                        RM {loading==true?<i className="pi pi-spin pi-spinner" style={{fontSize:"30px",marginLeft:"20px"}}/>:parseFloat(parseFloat(!memberBalance.share?0:memberBalance.share) + parseFloat(!memberBalance.subscription?0:memberBalance.subscription)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                    </Box>
                                </Box>
                            </Box>
                             <Box sx={{ display: "flex", alignItems: "center",flexShrink:0 , border:transType=="loan_transactions"&&"1px solid #288d2c",cursor:"pointer" }} onClick={(e)=>handleTransactionType(e,"loan")}>
                                <Box component='img' sx={{ width: "36px", height: "36px" }} src={SubsBalIcon} />
                                <Box sx={{ display: "flex", flexDirection: "column" }} >
                                    <Box  className="pgh-heading" >Loan Balance</Box>
                                    <Box className="fn-h1">
                                        RM {loading==true?<i className="pi pi-spin pi-spinner" style={{fontSize:"30px",marginLeft:"20px"}}/>:memberBalance.loanbalance?parseInt(memberBalance.loanbalance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):(0).toFixed(2)}
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center",flexShrink:0  , border:transType=="specific_transactions"&&"1px solid #288d2c",cursor:"pointer"}} onClick={(e)=>handleTransactionType(e,"sd")}>
                                <Box component='img' sx={{ width: "36px", height: "36px" }} src={BonusBalIcon} />
                                <Box sx={{ display: "flex", flexDirection: "column" }} >
                                    <Box  className="pgh-heading" >Specific Deposit Balance</Box>
                                    <Box className="fn-h1">
                                        RM {loading==true?<i className="pi pi-spin pi-spinner" style={{fontSize:"30px",marginLeft:"20px"}}/>:memberBalance.specific_deposit?parseInt(memberBalance.specific_deposit).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):(0).toFixed(2)}
                                    </Box>
                                </Box>
                            </Box>
                           <Box sx={{ display: "flex", alignItems: "center" ,flexShrink:0 , border:transType=="bonus_transactions"&&"1px solid #288d2c",cursor:"pointer"}} onClick={(e)=>handleTransactionType(e, "bonus")}>
                                <Box component='img' sx={{ width: "36px", height: "36px" }} src={BonusBalIcon} />
                                <Box sx={{ display: "flex", flexDirection: "column" }} >
                                    <Box className="pgh-heading" >Bonus Balance</Box>
                                    <Box className="fn-h1">
                                        RM {loading==true?<i className="pi pi-spin pi-spinner" style={{fontSize:"30px",marginLeft:"20px"}}/>:memberBalance.bonus?parseInt(memberBalance.bonus).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):(0).toFixed(2)}
                                    </Box>
                                </Box>
                            </Box>
                            </Box>
                            {
                                transType !== "loan_transactions"  && transType !== "specific_transactions" &&
                                <div className="card flex justify-content-center" style={{width:"280px"}}>
                                <Box>
                                <MuiDaterange 
                                    label="Select Date Range" 
                                    value={dates} 
                                    onChange={(newValue) => {
                                        setDates(newValue)
                                        console.log("setdates",dates);
                                        }}
                                    onKeyUp={(event) => {
                                        if (event.key === 'Enter') {
                                            console.log("My enter event",event.target.value);
                                            handleTransType(transType)
                                        }
                                    }
                                }
                                    />
                                    {/* <Calendar className="p-inputgroup" value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" style={{ width: "250px", height: "48px" }} readOnlyInput placeholder='mm/dd/yyyy - mm/dd/yyyy' />
                                    <i className="pi pi-calendar" style={{ color: "#CBD5E1", position: "absolute", right: "12px", color: "#64748B", height: "24px", width: "24px", display: "flex", alignItems: "center" }} />
                                    <CalendarTodayIcon sx={{position:"absolute",right:"12px",color:"#64748B",height:"24px",width:"24px"}}/> */}
                                </Box>
                            </div>
                            }                            
                       
                        </Box>
                        {transType === 'loan_transactions' &&
                        <Grid>
                        <DataTable id="datatable" value={loanDetails}  selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)} dataKey="loandetailid" loading={loading}
                    onRowSelect={(e) => onRowSelect(e, 'loan')} onRowDeselect={onRowUnselect}  rows={10} style={{ backgroundColor: "#F1F5F9", width: '100%' }} scrollable scrollHeight="650px" removableSort resizableColumns emptyMessage="No Transactions found."
                              >
                            <Column header="SN" headerStyle={{ width: '20px' }} body={(data, options) => options.rowIndex + 1} frozen style={{width:"30px",textAlign:"center"}}></Column>
                            <Column field="loanid" sortable header="Loan No" filterField="chequedate" filterPlaceholder="Search" style={{width: "120px", whiteSpace: 'normal',textAlign:"center"}}/>
                            <Column field="loanstartdate" sortable header="Loan Start" filterField="transdetailid"  filterPlaceholder="Search" style={{width: "120px", whiteSpace: 'normal',textAlign:"center"}} body={loanStartDate} />
                            <Column field="loanexpirydate" sortable header="Loan End Dt" filterField="transdetailid"  filterPlaceholder="Search" style={{width: "120px", whiteSpace: 'normal',textAlign:"center"}} body={loanEndDate} />
                            <Column field="principalamount" sortable header="Principal (RM)" filterField="transdetailid"  filterPlaceholder="Search" style={{width: "120px", textAlign: "center", whiteSpace: 'normal'}} body={data=>parseInt(data.principalamount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/>
                            <Column field="interestamount" sortable header="Interest (%)" filterField="transdetailid"  filterPlaceholder="Search" style={{width: "120px", textAlign: "center", whiteSpace: 'normal' }} body={data=>parseInt(data.interestamount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/>
                            <Column field="amountbeforededuction" sortable header="# Total Loan Amt (RM)" filterField="transdetailid"  filterPlaceholder="Search" style={{width: "160px", textAlign: "center", whiteSpace: 'normal'}} body={data=>parseInt(data.amountbeforededuction).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/>
                            <Column field="rateofinterest" sortable header="# intRate (%)" filterField="transdetailid"  filterPlaceholder="Search" style={{width: "140px", whiteSpace: 'normal',textAlign:"center"}}/>
                            <Column field="noofinstalment" sortable header="# of install" filterField="transdetailid"  filterPlaceholder="Search" style={{width: "140px", whiteSpace: 'normal',textAlign:"center"}}/>
                            <Column field="emiamount" sortable header="# of install Year" filterField="transdetailid"  filterPlaceholder="Search" style={{ width: "150px", whiteSpace: 'normal',textAlign:"center"}} body={(item)=>item.noofinstalment/12}/>
                            <Column field="emiamount" sortable header="Mthly Inst (RM)" filterField="transdetailid"  filterPlaceholder="Search" style={{width: "120px", textAlign: "center", whiteSpace: 'normal'}} body={data=>parseFloat(data.emiamount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/>
                            <Column field="loanbalanceamount" sortable header="LoanBalance (RM)" filterField="transdetailid"  filterPlaceholder="Search" style={{ width: "140px", textAlign: "center", whiteSpace: 'normal'}} body={data=>parseInt(data.loanbalanceamount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/>
                            {/* <Column  header="Select" body={seleceButton} style={{width:"500px"}}/> */}

                        </DataTable>

                            
                        </Grid>}
                        {transType === 'specific_transactions' &&
                        <Grid>
                        <DataTable id="datatable" value={sdDetails} selectionMode="single" dataKey="depositid" loading={loading} selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)} onRowSelect={(e) => onRowSelect(e, 'sd')} onRowDeselect={onRowUnselect}
                    rows={10} style={{ backgroundColor: "#F1F5F9" }} scrollable scrollHeight="650px" removableSort resizableColumns emptyMessage="No Transactions found."
                              >
                            <Column header="SN" headerStyle={{ width: '500px' }} body={(data, options) => options.rowIndex + 1} frozen></Column>
                            <Column field="depositid" sortable header="S_DepositNo" filterField="chequedate" filterPlaceholder="Search" style={{width:"500px", textAlign:"center"}}/>
                            <Column field="depositdate" sortable header="Date" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px", textAlign:"center"}} body={sdStartDate} />
                            <Column field="expirydate" sortable header="Expiry Date" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px", textAlign:"center"}} body={sdEndDate} />
                            <Column field="narration" sortable header="Narration" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px",textAlign:"center"}} />
                            <Column field="depositamount" sortable header="Deposit Amt" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px",textAlign:"center"}} />
                            <Column field="period" sortable header="Period" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px",textAlign:"center"}} />
                            <Column field="interestrate" sortable header="Int Rate" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px",textAlign:"center"}}/>
                            <Column field="sdbalance" sortable header="Balance" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px",textAlign:"center"}}/>
                            {/* <Column  header="Select" body={seleceButton} style={{width:"500px"}}/> */}

                        </DataTable>

                            
                        </Grid>}
                        <Box id="memberdetails" ref={componentExport}>

                            <div className="card">
                                <DataTable id="datatable" value={selected.length==0 ? transType === 'specific_transactions'||transType === 'loan_transactions'?[]:transDetails.data:selected} style={{ backgroundColor: "#F1F5F9" }} rows={20} loading={loading} scrollable scrollHeight="650px" resizableColumns removableSort
                                    emptyMessage="No Transactions found.">
                                    <Column field="transdate" sortable header="Date" headerStyle={{textAlign:"center", paddingLeft:"50px"}} filterField="transdate" body={dateOfJoinBody} filterPlaceholder="Search" style={{ width: "70px", textAlign:"center" }} />
                                    <Column field="bankname" sortable header="Coop Bank / Mno Bank" headerStyle={{paddingLeft:"60px"}} filterField="bankname" filterPlaceholder="Search" style={{ width: "200px", textAlign:"center"}} />
                                    <Column field="chequeno" sortable header="Cheque No" headerStyle={{paddingLeft:"70px"}} filterField="chequeno" filterPlaceholder="Search" style={{ width: "200px", whiteSpace: 'normal' , textAlign:"center"}} />
                                    <Column field="transdetailid" sortable header="Receipt No. / PV No." headerStyle={{paddingLeft:"50px"}} filterField="transdetailid" filterPlaceholder="Search" style={{ width: "200px", whiteSpace: 'normal', textAlign:"center" }} />
                                    <Column field="narration" sortable header="Narration" headerStyle={{paddingLeft:"70px"}} filterField="narration" filterPlaceholder="Search" style={{ width: "200px", whiteSpace: 'normal', textAlign:"center" }} />
                                    <Column field="payment_in" sortable header="Deposits / Receipts" headerStyle={{paddingLeft:"50px"}} filterField="payment_in" filterPlaceholder="Search" style={{ width: "250px" ,textAlign:"center", whiteSpace: 'normal'}} body={numFormatpayment_in}/>
                                    <Column field="payment_out" sortable header="Payments" headerStyle={{paddingLeft:"50px"}} filterField="payment_out" filterPlaceholder="Search" style={{ width: "150px" ,textAlign:"center", whiteSpace: 'normal'}} body={numFormatpayment_out} />
                                    {transType === 'loan_transactions' ? null : <Column field="balance" sortable header="Accumalated Balance" headerStyle={{paddingLeft:"50px"}} filterField="balance" filterPlaceholder="Search" style={{ width: "150px" ,textAlign:"center", whiteSpace: 'normal'}} body={numFormatbalance} />}
                                    { transType=='specific_transactions' && <Column field="loanno" sortable header="S_DepositNo" headerStyle={{paddingLeft:"50px"}} filterField="loanno" filterPlaceholder="Search" style={{ width: "250px", whiteSpace: 'normal', textAlign:"center" }} />}
                                </DataTable>
                            </div>
                        </Box>
                    </Box>

                </Box>
            </Box>
        </PrimeReactProvider>
    )
}