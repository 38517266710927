import { Box, Typography, Button } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ClearAllIcon from '@mui/icons-material/ClearAll';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DownloadIcon from '@mui/icons-material/Download';
import TableChartIcon from '@mui/icons-material/TableChart';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { InputText } from "primereact/inputtext";
import { Form } from "./form";
import { Dialog } from 'primereact/dialog';
import SettingsIcon from '@mui/icons-material/Settings';
import { useState } from "react";
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from "primereact/checkbox";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { CSVLink } from 'react-csv'
import { updateModalOpen } from "../redux/executiveReducer";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import * as XLSX from 'xlsx';

const NewMemberReport = () => {
    const newMemberData = useSelector(state => state.executive.newMemberData)
    const [filteredData, setFilterData] = useState()
    const [visible, setVisible] = useState(false);
    const [customers, setCustomers] = useState(null);
    const [filterState, setFilterState] = useState(false)
    const [loading, setLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [totalRecords, setTotalRecords] = useState(null)
    const [arrangeTable, setArrangeTable] = useState(false)
    const [seperatedColumn, setSeperatedColumn] = useState([])
    const [selectedColumn, setSelectedColumn] = useState([])
    const [exportCSV, setExportCSV] = useState([])
    const formPayload = useSelector(state => state.executive.formPayload)
    const modalOpen = useSelector(state => state.executive.modalOpen)
    const dispatch = useDispatch()
    const [download, setDownload] = useState([])
    const isSidebarOpen = useSelector((state) => state.executive.open);
   

    const formatDate = (date) => {
        const day = date.getDate();
        const monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const month = date.getMonth()+1
        const year = date.getFullYear();
      
        return `${day}/${month}/${year}`;
      };
    
    useEffect(() => {
        setFilterData(newMemberData)
        //console.log(newMemberData[0])
        loadLazyData()
    }, [newMemberData])
    let networkTimeout = null
    const loadLazyData = () => {
        setLoading(true);

        if (networkTimeout) {
            clearTimeout(networkTimeout);
        }

        //imitate delay of a backend call
        networkTimeout = setTimeout(() => {

            setTotalRecords(newMemberData.length);
            setCustomers(newMemberData);
            setLoading(false);

        }, Math.random() * 1000 + 250);
    };

    const CustomFilter = (options) => {

        return (
            <InputText style={{ width: "182px", height: "36px" }} placeholder="search" value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value,)} />
        );
    };

    const AgeFilter = (options) => {
        //console.log(options)
        return (
            <InputText style={{ width: "182px", height: "36px" }} placeholder="search" value={options.value} />
        );
    }
    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => { options.filterApplyCallback(e.target.value); }} dateFormat="dd/mm/yyyy" placeholder="dd/mm/yyyy" />;
    };
    const [globalFilterValue, setGlobalFilterValue] = useState('');


    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {

            setSelectAll(true);
            setSelectedCustomers(newMemberData);

        } else {
            setSelectAll(false);
            setSelectedCustomers([]);
        }
    };
    const onSelectionChange = (event) => {
        const value = event.value;
        //console.log(value)
        setSelectedCustomers(value);
        //console.log(selectedCustomers)
        setSelectAll(value.length === totalRecords);
    };
    useEffect(() => {
        //console.log(selectedCustomers)
    }, [selectedCustomers])

    const calculateAge = (e) => {
        // console.log("------",e.dob)
        let dob = e.dob
        const birthDate = new Date(dob);
        const currentDate = new Date();

        // Calculate age
        let age = currentDate.getFullYear() - birthDate.getFullYear();
        // console.log(dob,age)
        // Adjust age if the birthday hasn't occurred yet this year
        if (
            currentDate.getMonth() < birthDate.getMonth() ||
            (currentDate.getMonth() === birthDate.getMonth() &&
                currentDate.getDate() < birthDate.getDate())
        ) {
            age -= 1;
        }
        if (age < 0) {
            age = 0
        }
        return age;
    }
    // const dateOfJoinBody = (rowData) => {
    //     const originalDate = new Date(rowData.doj);
    //     const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    //     const day = originalDate.getDate().toString().padStart(2, '0');
    //     const year = originalDate.getFullYear();

    //     const formattedDate = `${day}-${month}-${year}`

    //     return formattedDate;
    // }
    const onColumnChange = (e) => {
        let column = [...columnVisible]
        let _selectedCategories = [...seperatedColumn];
        if (e.checked) {
            //console.log(e.value)
            let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = true; return item })
            //console.log(column)
            deselect = [...column, ...deselect];
            deselect = [...new Set(deselect)]
            //console.log(deselect)
            setSelectedColumn(deselect)
            _selectedCategories.push(e.value);
        }
        else {
            //console.log(e.value)
            let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = false; return item })
            //console.log(column)
            deselect = [...column, ...deselect];
            deselect = [...new Set(deselect)]
            //console.log(deselect)
            setSelectedColumn(deselect)
            _selectedCategories = _selectedCategories.filter(category => category.header !== e.value.header);


        }
        setSeperatedColumn(_selectedCategories)

    };
    const applyFilter = () => {
        let columns = [...columnVisible]


        // columns=columns.map(column=>{
        //     selectedColumn.some(item=>)
        //     // console.log(array)
        //     return column

        // })
        //console.log(columns)
        //console.log(selectedColumn)

        setColumneVisible(selectedColumn)
        setArrangeTable(false)
        let exportHeaders = [...exportCSV]
        //console.log(exportHeaders)
        exportHeaders = selectedColumn.filter((item) => item.visible).map(item => { return { label: item.header, key: item.field } })
        //console.log(exportHeaders)

        setExportCSV(exportHeaders)
    }

    useEffect(() => {
        if (newMemberData.length > 0) {
            let exportHeaders = [...exportCSV]
            exportHeaders = columnVisible.filter((item) => item.visible).map(item => { return { label: item.header, key: item.field } })

            setExportCSV(exportHeaders)


        }


    }, [])

    const decimalshare = (rowData) => {

        if (isNaN(rowData.sharebalance) || rowData.sharebalance == null)
            return "0.00";

        return parseFloat(rowData.sharebalance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const decimalsub = (rowData) => {

        if (isNaN(rowData.subscriptionbalance) || rowData.subscriptionbalance == null)
            return "0.00";

        return parseFloat(rowData.subscriptionbalance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const decimalLoan = (rowData) => {

        if (isNaN(rowData.loanbalance) || rowData.loanbalance == null)
            return "0.00";

        return parseFloat(rowData.loanbalance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const decimalSd = (rowData) => {

        if (isNaN(rowData.sdbalance) || rowData.sdbalance == null)
            return "0.00";

        return parseFloat(rowData.sdbalance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const decimalBr = (rowData) => {

        if (isNaN(rowData.brbalance) || rowData.brbalance == null)
            return "0.00";

        return parseFloat(rowData.brbalance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const decimalBonus = (rowData) => {

        if (isNaN(rowData.bonus) || rowData.bonus == null)
            return "0.00";

        return parseFloat(rowData.bonus).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const [columnVisible, setColumneVisible] = useState([
        { field: "memberid", header: "M No", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: true },
        { field: "membername", sortable: true, header: "MemberName", filterField: "membername", filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: true },
        { field: "newicno", sortable: true, filter: true, header: "NRIC New", filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false },
        { field: "oldicno", sortable: true, header: "NRIC Old", filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false },
        { field: "doj", sortable: true, header: "Date Joined", filter: true, filterPlaceholder: "Search", filterElement: dateFilterTemplate, visible: true, filterField: "doj", frozen: false },
        { field: "age", sortable: true, header: "Age", filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false },
        { field: "sex", sortable: true, header: "Sex", filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false },
        { field: "race", sortable: true, header: "Race", filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false },
        { field: "photo", sortable: true, header: "Photo", filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false },
        // { field: "mykadnominee", sortable: true, header: "Nominee MyKad", filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false,body: rowData => rowData.mykadnominee?"Y":"N"  },
        { field: "sharebalance", header: "Share", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false, body: decimalshare },
        { field: "subscriptionbalance", sortable: true, header: "Subscription", filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false, body: decimalsub },
        { field: "loanbalance", header: "Loan", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false, body: decimalLoan },
        { field: "sdbalance", header: "SD", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false, body: decimalSd },
        { field: "brbalance", header: "Borrowings", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false, body: decimalBr },
        { field: "bonus", header: "Bonus", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false, body: decimalBonus },
        // { field: "nomail", header: "No Mailer", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false,body: rowData => rowData.nomail?"Y":"N" },
        // { field: "deceased", header: "Deceased", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false,body: rowData => rowData.deceased?"Y":"N"  },
        // { field: "disciplinaryaction", header: "Displ.Action", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false,body: rowData => rowData.disciplinaryaction?"Y":"N" },
        // { field: "director", header: "Director", filter: true, sortable: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false,body: rowData => rowData.director?"Y":"N"  },
        // { field: "observer", header: "Observer", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false,body: rowData => rowData.observer?"Y":"N"  },
        // { field: "delegates", header: "Delegate", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false,body: rowData => rowData.delegates?"Y":"N"  },
        // { field: "company", header: "Company", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false,body: rowData => rowData.company?"Y":"N" },
        // { field: "junior", header: "Junior", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false,body: rowData => rowData.junior?"Y":"N"  },
        // { field: "terminated", header: "Terminated", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false },
        { field: "estate", sortable: true, header: "Estate", filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false },
        { field: "region", sortable: true, header: "Region", filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false },
        { field: "state", sortable: true, header: "State", filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false },
        // { field: "postalcode", sortable: true, header: "Post Code", filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, frozen: false },
    ])
    const globalFilterFields = columnVisible.map((obj) => { return obj.field })
    const [filters, setFilters] = useState(() => {
        let filter_obj = {}
        filter_obj['global'] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        columnVisible.map((obj) => {
            filter_obj[obj.field] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        })
        return filter_obj
    }
        //     {
        //     global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //     membername: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //     sex: { value: null, matchMode: FilterMatchMode.EQUALS },
        //     estate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //     state: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //     share: { value: null, matchMode: FilterMatchMode.EQUALS },
        //     subscription: { value: null, matchMode: FilterMatchMode.EQUALS },
        //     memberid: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //     photo: { value: null, matchMode: FilterMatchMode.IN },
        //     race: { value: null, matchMode: FilterMatchMode.IN },
        //     doj: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //     age:{value:null, matchMode: FilterMatchMode.CONTAINS}


        // }
    );
    const clearFilter = () => {
        let filter_obj = {}
        filter_obj['global'] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        columnVisible.map((obj) => {
            filter_obj[obj.field] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        })
        setFilters(filter_obj)
        // setFilters(
        //     {
        //         global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         membername: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         sex: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         estate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         state: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         share: { value: null, matchMode: FilterMatchMode.EQUALS },
        //         subscription: { value: null, matchMode: FilterMatchMode.EQUALS },
        //         memberid: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         photo: { value: null, matchMode: FilterMatchMode.IN },
        //         race: { value: null, matchMode: FilterMatchMode.IN },
        //         doj: { value: null, matchMode: FilterMatchMode.CONTAINS },
        //         age:{value:null, matchMode: FilterMatchMode.CONTAINS}

        //     }
        // )
        setGlobalFilterValue('')
        setFilterState(false)
    }
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        //console.log(_filters)

        _filters['global'].value = value;
        //console.log(_filters)

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const addCurrencyAndCommafy = (num) => {

        let str = parseFloat(num).toFixed(2).toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        return "RM " + str.join('.');

    }

    const shTotal = () => {
        let total = 0;

        for (let row of selectedCustomers.length>0?selectedCustomers:newMemberData) {

            if (isNaN(row.sharebalance) || row.sharebalance == null)
                total += 0;
            else
                total += parseFloat(row.sharebalance);
        }

        return total;
    };

    const sbTotal = () => {
        let total = 0;

        for (let row of selectedCustomers.length>0?selectedCustomers:newMemberData) {

            if (isNaN(row.subscriptionbalance) || row.subscriptionbalance == null)
                total += 0;
            else
                total += parseFloat(row.subscriptionbalance);
        }

        return total;
    };
    const sdTotal = () => {
        let total = 0;

        for (let row of selectedCustomers.length>0?selectedCustomers:newMemberData) {

            if (isNaN(row.sdbalance) || row.sdbalance == null)
                total += 0;
            else
                total += parseFloat(row.sdbalance);
        }

        return total;

    }
    const borrowTotal = () => {
        let total = 0;

        for (let row of selectedCustomers.length>0?selectedCustomers:newMemberData) {

            if (isNaN(row.brbalance) || row.brbalance == null)
                total += 0;
            else
                total += parseFloat(row.brbalance);
        }

        return total;

    }
    const loanTotal = () => {
        let total = 0;

        for (let row of selectedCustomers.length>0?selectedCustomers:newMemberData) {

            if (isNaN(row.loanbalance) || row.loanbalance == null)
                total += 0;
            else
                total += parseFloat(row.loanbalance);
        }

        return total;

    }

    const bonusTotal=()=>{
        let total = 0;
        for (let row of selectedCustomers.length>0?selectedCustomers:newMemberData) {

            if (isNaN(row.bonus) || row.bonus == null)
                total += 0;
            else
                total += parseFloat(row.bonus);
        }

        return total;

    }

    const grandTotal = () => {

        return "GrandTotal : " + (shTotal() + sbTotal() + loanTotal() + sdTotal() + borrowTotal() + bonusTotal())
    }
    const formattingdate = (date) => {
        const originalDate = new Date(date);
        const year = String(originalDate.getUTCFullYear()).slice(2,4);
        const month = String(originalDate.getUTCMonth() + 1).padStart(2, '0');
        const day = String(originalDate.getUTCDate()).padStart(2, '0');

        let formattedDate = `${day}-${month}-${year}`;
        return formattedDate;

    }

    const shaTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Share Total : </span>{addCurrencyAndCommafy(shTotal())}
            </span>
        )
    }

    const subTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Subs Total : </span>{addCurrencyAndCommafy(sbTotal())}
            </span>
        )
    }

    const lTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Loan Total : </span>{addCurrencyAndCommafy(loanTotal())}
            </span>
        )
    }

    const SDTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>SD Total : </span>{addCurrencyAndCommafy(sdTotal())}
            </span>
        )
    }

    const BorrowTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Borrow Total : </span>{addCurrencyAndCommafy(borrowTotal())}
            </span>
        )
    }

    const BonusTotal=()=>{
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Bonus Total : </span>{addCurrencyAndCommafy(bonusTotal())}
            </span>
        )
    }
    const footGroup = (
        <ColumnGroup>
            <Row>
                {/* <Column footer={grandTotal} colSpan={15} footerStyle={{ textAlign: 'right' }} /> */}

                <Column footer={shaTotal} colSpan={12} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={subTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={lTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={SDTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={BorrowTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={BonusTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />

            </Row>
        </ColumnGroup>

    )

    const exportExcel = () => {
        let headers = columnVisible.map(col => ({ header: col.header, key: col.field }));
        headers = [{ header: "SN", key: "SN" }, ...headers];
    
        const data = selectedCustomers.length>0?selectedCustomers.map((item, index) => {
            return {  SN: index + 1, ...item };
          }):newMemberData.map((item, index) => {
            return { SN: index + 1, ...item };
        });
    
        const headersBold = headers.map(({ header }) => ({
            v: header,
            t: 's',
            s: {
                font: { bold: true, size: 10 }
            }
        }));
    
        const additionalRow1 = [
            ['','KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)'],
            [''],
            ['','New Member Report'],
            ['','Sort Order :'],
            ['',`Date Joined :${formattingdate(formPayload['NMC'].dr[0])+" TO "+formattingdate(formPayload['NMC'].dr[1])}`],
            ['',`Date Range Report for the Period`],
            ['',`Membership No. :${formPayload['NMC'].mid.length === 0?" - Not Selected - ": formPayload['NMC'].mid[0]+" TO "+formPayload['NMC'].mid[1]}`],
            [],
            [],
            [],
        ];

        const additionalRow2 = [
            ['Total :','','','','','','','','','',addCurrencyAndCommafy(shTotal()),addCurrencyAndCommafy(sbTotal()),addCurrencyAndCommafy(loanTotal()),addCurrencyAndCommafy(sdTotal()),addCurrencyAndCommafy(borrowTotal()),addCurrencyAndCommafy(bonusTotal())],
        ];

        const additionalRow3 = [
            ['Print Date :',formatDate(new Date())],
        ];
    
        // Convert array of objects to array of arrays (2D array) for the data section
        const dataRows = data.map(obj => headers.map(h => {
            let value;
            const amountRelatedKeys = ["sharebalance","subscriptionbalance","loanbalance","sdbalance","brbalance","bonus"]
            if(amountRelatedKeys.includes(h.key)){
                value = parseInt(parseFloat(obj[h.key]).toFixed(2).split('.')[1]) === 0?parseInt(obj[h.key]):obj[h.key]
            }
            else
                value = obj[h.key]
            return ({
            v: value,
            t: typeof value === 'number' ? 'n' : 's',
            s: {
                alignment: { horizontal: typeof value === 'number' ? 'right' : 'left' },
                font: { size: 8, name: 'Times New Roman' }
            }
        })}));
        console.log(dataRows)
    
        const dataArray = [...additionalRow1, headersBold, ...dataRows, ...additionalRow2, ...additionalRow3];
    
        // Convert data to worksheet
        const ws = XLSX.utils.aoa_to_sheet(dataArray);
    
        // Create workbook and add the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
        // Generate XLSX file and trigger download
        XLSX.writeFile(wb, 'New Member Report.xlsx');
    };

    return (
        <Box className="fulldiv" style={{ width: isSidebarOpen?"calc(100vw - 319px)":"calc(100vw - 95px)", height: "calc(100vh - 64px)", }} >



            <Dialog visible={modalOpen} style={{ width: '790px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#F8FAFC", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={false} closable={false} resizable={false} >


                <Form api="NMC" formAlone={true} />



            </Dialog>
            <Box sx={{ marginLeft: "24px", padding: "8px 0px 8px 0px", marginRight: "16px", }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", gap: "12px", }}>
                        <SummarizeOutlinedIcon sx={{ fontSize: "32px", color: "#288d2c" }} />
                        <Box>
                            <Typography sx={{ fontSize: "20px", fontWeight: "600", lineHeight: "28px", color: "#1E293B" }}>New Member Report</Typography>
                            <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#64748B" }}>Found <span style={{ fontWeight: "700" }}>{newMemberData.length}</span> records on <span style={{ fontWeight: "700" }}>{formattingdate(formPayload['NMC']?.dr[0],"none")}</span> to <span style={{ fontWeight: "700" }}>{formattingdate(formPayload['NMC']?.dr[1],"none")}</span> with applied Configure</Typography>
                        </Box>

                    </Box>
                    <Box sx={{ alignSelf: "end" }}>
                        <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#22c54e" }, gap: "8px", fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "201px", height: "48px" }} startIcon={<SettingsIcon sx={{ fontSize: "24px", }} />} onClick={() => dispatch(updateModalOpen(true))}>Data Re-Configure</Button>
                    </Box>

                </Box>
                <Box sx={{ marginTop: "24px", }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between",width:"100%",flexWrap:"wrap" }}>


                        <Box sx={{ display: "flex", alignItems: "center" }}>

                            <Box>

                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText style={{ width: "655px", height: "36px" }} placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} />
                                </span>
                            </Box>
                            <Box sx={{ color: "#64748B", width: "178px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "16px", alignSelf: "end", cursor: "pointer", textTransform: "none", gap: "8px" }} onClick={clearFilter}>
                                <ClearAllIcon sx={{ fontSize: "24px", color: "#CBD5E1" }} />
                                <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#CBD5E1" }}></Typography>
                                Clear Filter
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "16px", alignSelf: 'end' }}>
                            <Box sx={{ padding: "12px" }}> {filterState ? <FilterAltOffIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(false)} /> : <FilterAltIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(true)} />}</Box>
                            <Button type="button" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" ><DownloadIcon sx={{ color: "#64748B", fontSize: "24px" }} /></Button>
                            <Box sx={{ padding: "12px" }}><TableChartIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => { setArrangeTable(true); setSeperatedColumn(columnVisible) }} /> </Box>

                        </Box>

                    </Box>
                    <Box sx={{ marginTop: "24px", }}>
                        <div className="card">
                            <DataTable value={newMemberData} filters={filters} paginator footerColumnGroup={footGroup} rows={10} dataKey="memberid" style={{ backgroundColor: "#F1F5F9" }} scrollable scrollHeight="650px" filterDisplay={filterState ? "row" : ""} loading={loading} columnResizeMode="expand" removableSort resizableColumns
                                selection={selectedCustomers} selectionMode="checkbox" onSelectionChange={onSelectionChange} selectAll={selectAll} filterIcon={false} onSelectAllChange={onSelectAllChange} emptyMessage="No customers found." globalFilterFields={globalFilterFields}>
                                <Column styleClass="custom-column-style" selectionMode="multiple" headerStyle={{ width: "100px", background: "#F1F5F9" }} frozen />

                                {columnVisible.filter(col => col.visible).map(col => <Column className="custom-column-style" style={{ width: "500px" }} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                            </DataTable>
                        </div>
                    </Box>
                </Box>


            </Box>
            <Dialog visible={arrangeTable} style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#F8FAFC", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={true} closable={false} resizable={false} >


                <Box style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#F8FAFC", padding: "30px 30px 0px 30px", borderRadius: "0px", display: "flex", flexDirection: "column", gap: "24px" }}>
                    <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F8FAFC" }}>
                        <TableChartIcon sx={{ fontSize: "24px", color: "#288d2c" }} />
                        <Typography sx={{ fontSize: "18px", fontWeight: 600, lineHeight: "28px" }}>Table Column Configure</Typography>
                    </Box>
                    <Box>


                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText style={{ width: "461px", height: "36px" }} placeholder="Search" onChange={onGlobalFilterChange} />
                        </span>

                    </Box>
                    <Box sx={{ height: "461px", overflowY: "scroll", display: "flex", flexDirection: "column", gap: "20px" }}>

                        {columnVisible.map((column, ind) => {
                            return (

                                <div key={column.header} style={{ gap: "8px", display: "flex", alignItems: "center" }}>
                                    <Checkbox inputId={column.header} value={column} onChange={onColumnChange} disabled={column.header == "Member #" || column.header == "MemberName"} checked={column.visible} />
                                    <label htmlFor={column.header} className="ml-2">
                                        {column.header}
                                    </label>
                                </div>

                            );
                        })}

                    </Box>
                    <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F1F5F9", paddingTop: "24px", paddingBottom: "24px", justifyContent: "space-between", marginLeft: "-30px", marginRight: "-30px", }}>

                        <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#22c54e" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "138px", height: "48px", marginLeft: "30px" }} startIcon={<CheckCircleOutlineIcon />} onClick={applyFilter} >Apply</Button>
                        <Button variant="outlined" sx={{ borderColor: "#64748B", color: "#64748B", "&:hover": { borderColor: "#64748B" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", marginRight: "30px" }} startIcon={<ClearIcon sx={{ color: "#64748B", fontSize: "24px" }} />} onClick={() => setArrangeTable(false)}>Clear</Button>


                    </Box>
                </Box>


            </Dialog >

        </Box >
    )
}


export default NewMemberReport