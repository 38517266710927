import React, { useState } from 'react';
import {Grid, Box, TextField, InputAdornment, IconButton, Button, Link, Typography} from '@mui/material';
import './account.css';
import '../../styles/brandingStyles/branding.css';
import resetPassBanner from '../../assets/userAccount/resetPassBanner.png';
import lockGreen from '../../assets/userAccount/lockGreen.png';
import { PersonOutline, LockOutlined, VisibilityOutlined, VisibilityOffOutlined, LoginOutlined, AlternateEmailOutlined, PhoneIphoneOutlined} from '@mui/icons-material';
import axios from 'axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import CustomAxios from '../../utils/CustomAxios';

export function ResetPassword(){
    
    const [email, setEmail] = useState('');
    const [showPassword, setShowPassword] = useState({
        password:false,
        conPassword:false
    });
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [resetLinkSent, setResetLinkSent] = useState(false);
    const [Errmessage, setErrMessage] = useState('')
    const [passErrmessage, setPassErrMessage] = useState('')
    const navigate = useNavigate();

    const handleTogglePasswordVisibility = (val) => {
        if(val === 'password'){
            setShowPassword((prev) => ({ ...prev, password: !prev.password }));

        }
        else{

            setShowPassword((prev) => ({ ...prev, conPassword: !prev.conPassword }));

        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        if (password.length<8){
            setPassErrMessage("Password must be at least 8 characters long.")
            setErrMessage("")
        }
        // if (setErrMessage!="" || setPassErrMessage!=""){
        //     setPassword("")
        //     setConfirmPassword("")
        // }
        // Add your signup logic here
        else{
            console.log("we are here")
        if(password === confirmPassword){

            console.log('Signup data:', { password});
            let details = JSON.parse(window.sessionStorage.getItem('details'))
            // e.preventDefault();
    
            const payload = {
                email: details.oasis_id,
                // memberid: 31309,
                password: password
            };
    
            // Send data to the API endpoint using Axios (replace with your actual API endpoint)
            CustomAxios.post(`oasis/reset_password/`, payload)
            //axios.post('http://127.0.0.1:8000/oasis/reset_password/', payload)
                      .then(response => {
                // Handle API response as needed
                console.log('API Response:', response);
                if (response.data.message=="your password successfully changed"){
                    setResetLinkSent(true)
                }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        else{
            setPassErrMessage("")
            setErrMessage("Ensure your new password matches the confirmation.")
        }

    }};
    const isMobile = useMediaQuery({ maxWidth: 600 })
    return(
        <Box sx={[isMobile&&{backgroundColor:"#f2f6fc",minHeight:"100vh", marginTop:"60px"}]}>
            <Box sx={{ height: {sm:'initial',md:'100vh'} }}>
                <Grid container sx={{ height: "100vh" ,backgroundColor:{xs:"#F1F5F9",sm:"#FFF"}}}>
                    <Grid item xs={12} sm={7} className='login-bg-primary' sx={[isMobile&&{backgroundColor:"#F8FAFC"},{display: "flex", alignItems: "center", justifyContent: "center"}]}>
                        <Box component='img' sx={{width: { xs: "100%", sm: "300px", md:"500px",lg:"initial" }}} src={resetPassBanner} />
                    </Grid>
                    <Grid item xs={12} sm={5}  sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Box className='login-bg-primary form' sx={[isMobile&&{backgroundColor:"#F8FAFC"}]}>
                            { resetLinkSent ?
                            <Box>
                                <Box sx={{mb:4}} className='text-center'>
                                    <Box sx={{mb:1}} className='fn-h3 fw-600 primary text-center'>Your password has been reset successfully</Box>
                                    <Box className='pgh text-center'>Please use your new password to log in</Box>
                                </Box>
                                <Button type="button" fullWidth variant="contained" color="primary" onClick={() => (navigate('/login'))} startIcon={<LoginOutlined />}>
                                    BACK TO LOGIN
                                </Button>
                            </Box>
                            : 
                            <form>
                                <Box sx={{mb:1}} className='fn-h3 fw-600 primary text-center'>Reset your password</Box>
                                
                                <Box className='pgh text-center'>Enter a New Password below to  change your current password.</Box>
                                <Box sx={{mt:"30px",mb:4 }}>
                                    
                                    <Box sx={{mb:4}}>
                                        <Box sx={{mb:"8px", color:"#1E293B"}}>New Password <span style={{color:"#EF4444"}}>*</span></Box>
                                        <TextField
                                            placeholder="Enter New Password"
                                            fullWidth
                                            variant="outlined"
                                            sx={{mb:"8px"}}
                                            value={password}
                                            type={showPassword.password ? 'text' : 'password'}
                                            onChange={(e) => setPassword(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockOutlined />
                                                </InputAdornment>
                                                ),
                                                endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={()=>handleTogglePasswordVisibility('password')}>
                                                    {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined /> }
                                                    </IconButton>
                                                </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {passErrmessage!=""&&<Typography sx={{alignSelf:"stretch", color: "var(--Light-Text-Danger-Text, #EF4444)", fontFamily:"Inter", fontSize:"12px", fontStyle:"normal", fontWeight:"400", lineHeight:"16px"}}>{passErrmessage}</Typography>}
                                    </Box>
                                    
                                    <Box sx={{mb:4}}>
                                        <Box sx={{mb:"8px", color:"#1E293B"}}>Re-Confirm <span style={{color:"#EF4444"}}>*</span></Box>
                                        <TextField
                                            placeholder="Reconfirm password"
                                            fullWidth
                                            variant="outlined"
                                            sx={{mb:"8px"}}
                                            value={confirmPassword}
                                            type={showPassword.conPassword ? 'text' : 'password'}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockOutlined />
                                                </InputAdornment>
                                                ),
                                                endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={()=>handleTogglePasswordVisibility("conPassword")}>
                                                    {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined /> }
                                                    </IconButton>
                                                </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {Errmessage!=""&&<Typography sx={{alignSelf:"stretch", color: "var(--Light-Text-Danger-Text, #EF4444)", fontFamily:"Inter", fontSize:"12px", fontStyle:"normal", fontWeight:"400", lineHeight:"16px"}}>{Errmessage}</Typography>}
                                    </Box>

                                    <Button type="submit" fullWidth variant="contained" color="primary" onClick={handleSubmit} >
                                    RESET & SUBMIT
                                    </Button>
                                </Box>
                            </form> 
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
};