import { Box, Button, Menu, Typography, MenuItem } from "@mui/material";
import "../styles/myprojects.css"
import { Logout } from "@mui/icons-material";
import searchIcon from "../assets/myprojects/search (1).svg"
import arrowLeftmob from "../assets/create-project/arrow-leftmob.svg"
import sortIcon from "../assets/myprojects/sort-alpha-up (1).svg"
import chartImage from "../assets/myprojects/chart-graphic.svg"
import fileImage from "../assets/myprojects/Frame 248.svg"
import XLImage from "../assets/myprojects/excel_svgrepo.com (2).svg"
import barImage from "../assets/myprojects/card-body.svg"
import plusIcon from "../assets/myprojects/plus.svg"
import { MenuThreedot } from "../components/menuButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export function Myprojects({ details }) {
    const [projectfile, setprojectFile] = useState("")
    const [data, setData] = useState('file')
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState(false)
    const navigate = useNavigate()
    const username = details.username
    const credits = details.credits
    const creditsColor = details.creditsColor

    const { setMyprojects, projects, saved_data, handleChatOpen, openChart, handleOptionsClose, handleRenameOption, handleDeleteOption, handleOptionsClick } = details
    const [Optionsopen,setOptionsopen] = [details.Optionsopen]
    const [anchorEl, setAnchorEl] = useState(details.anchorEl);
    console.log(saved_data.sort())
    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/login');
    };
    const handleTouchStart = (e,prtn_i,fn_i) => {
        e.stopPropagation()
        if(!Optionsopen[prtn_i][fn_i]){

            setTimeout(() => {
                console.log(handleOptionsClick,e.target)
                setAnchorEl(e.target)
                handleOptionsClick(e,prtn_i,fn_i)
            }, 1000);
        }
    };
    return (
        <>
            {projectfile === "" && <div className="myproject">
                <div className="container">
                    <div className="threedotBox">
                        <div className="back" onClick={() => setMyprojects(false)}>
                            <img src={arrowLeftmob} className="back-icon" />
                        </div>
                        <Button
                        sx={{ color: "#fff" }}
                        onClick={handleLogout}
                        >
                            <Logout />
                        </Button>
                        {/* <MenuThreedot username={username} url={details.savedurl} credits={credits} creditsColor={creditsColor}/> */}
                    </div>
                    <div className="title">
                        <Typography sx={{ fontFamily: "inter", fontSize: "36px", fontStyle: "normal", fontWeight: "700", lineHeight: "40px", background: " linear-gradient(115deg, #00C2FF 31.83%, #9746FF 84.85%)", backgroundClip: "text", WebkitTextFillColor: "transparent" }}>My Projects</Typography>
                    </div>
                    <div className="input-box">
                        <div className="input-container">
                            <div className="input-section-mob">
                                <img src={searchIcon} alt="search" />
                                <input className="input" placeholder="Search by Project or Dataviz" onChange={(e) => setSearch(e.target.value)} />

                            </div>
                            <div className="filter" onClick={() => setSort(!sort)}>
                                <div className="sort">
                                    <img className="sort-Icon" src={sortIcon} alt="sort" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="recent">
                    <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px", color: "#FFF", marginTop: "24px" }}>Recent View</Typography>
                    <div className="recent-container">
                        <div className="recent-file">
                            <div className="chart-box">
                                <img src={chartImage} className="char-image"/>
                            </div>
                            <div className="chart-details">
                              <Typography sx={{ fontFamily: "inter", fontSize: "12px", fontStyle: "normal", fontWeight: "600", lineHeight: "16px", color: "#FFF"}}>title</Typography>


                            </div>

                        </div>

                    </div>
                </div> */}

                    <div className="recent">
                        <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px", color: "#FFF", marginTop: "24px" }}>Projects</Typography>
                        <div className="recent-container">
                            {projects ? (sort === false ? (Object.keys(projects).sort((a, b) => a.localeCompare(b))) : (Object.keys(projects).reverse())).filter((project) => {
                                console.log(project)
                                return search === '' ? projects : project.toLowerCase().includes(search.toLowerCase())
                            }).map((project_name) => (

                                <div className="recent-file" onClick={() => setprojectFile(project_name)}>
                                    <div className="chart-box">
                                        <img src={fileImage} className="char-image" />
                                    </div>
                                    <div className="chart-detail">
                                        <div className="file-name">
                                        <Typography  className={project_name.length>12?"text-scroll":""}  sx={{ fontFamily: "inter",maxWidth:"128px",width:"100%", fontSize: "12px", fontStyle: "normal", fontWeight: "600", lineHeight: "16px", color: "#FFF" }}>{project_name.charAt(0).toUpperCase()+project_name.slice(1)}</Typography>

                                        </div>
                                    </div>


                                </div>)) : null}

                        </div>
                    </div>


                </div>
            </div>}
            {projectfile !== "" &&
                <div className="myproject">
                    <div className="container">
                        <div className="threedotBox">
                            <div className="back" onClick={() => setprojectFile("")}>
                                <img src={arrowLeftmob} className="back-icon" />
                            </div>
                            <Button
                                sx={{ color: "#fff" }}
                                onClick={handleLogout}
                            >
                                <Logout />
                            </Button>
                        </div>
                        {projects ? Object.keys(projects).map((project_name) => (
                            project_name === projectfile &&
                            <div className="title">
                                <Typography sx={{ fontFamily: "inter", fontSize: "24px", fontStyle: "normal", fontWeight: "600", lineHeight: "32px", color: "#FFF", display: "flex", flexWrap: "wrap" }}>{project_name}</Typography>
                            </div>)) : ""}
                        <div className="input-box">
                            <div className="input-container">
                                <div className="input-section-mob">
                                    <img src={searchIcon} alt="search" />
                                    <input className="input" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />

                                </div>
                                <div className="filter" onClick={() => setSort(!sort)}>
                                    <div className="sort">
                                        <img className="sort-Icon" src={sortIcon} alt="sort" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="datas-option">
                            <div className={data === 'file' ? "tab-menu-data" : "tab-inactive-file"} onClick={() => setData("file")}>
                                <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>Data Source</Typography>

                            </div>
                            <div className={data === 'vizualization' ? "tab-menu-viz" : "tab-inactive-viz"} onClick={() => setData("vizualization")}>
                                <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>Data Visualization</Typography>

                            </div>
                        </div>
                        <div className="recent">
                            <div style={{ display: "flex", flexDirection: "column", height: data==="file"?"60vh":"", justifyContent: "space-between", marginBottom: "10px" }}>
                                <div className="recent-container">
                                    {data === 'file' && 
                                        projects ? Object.keys(projects).map((project_name,prtn_i) => (
                                            project_name === projectfile &&
                                            (sort === false ? projects[projectfile].sort((a, b) => a.localeCompare(b)) : projects[projectfile].reverse()).filter((project) => {
                                                console.log(projects[projectfile],search)
                                                return search === '' ? projects[projectfile] : project.toLowerCase().includes(search.toLowerCase())
                                            }).map((file,fn_i) => (
                                                
                                                <div onTouchStart={(e)=>{handleTouchStart(e,prtn_i,fn_i)}}
                                                className="recent-file"
                                                onClick={(e)=>{handleChatOpen(project_name, file)}}
                                                >
                                                    <div className="chart-box">
                                                        <img src={XLImage} className="char-image" />
                                                    </div>
                                                    <div className="chart-detail">
                                                    <Box sx={{width:"100%",display:"flex",justifyContent:"center"}}>
                                                                           <Box className="file-name" >
                                                        <Typography sx={{ fontFamily: "inter", fontSize: "12px", fontStyle: "normal", fontWeight: "600", lineHeight: "16px", color: "#FFF", maxWidth: "140px", width: "100%", display: "flex", flexWrap: "wrap", wordBreak: "break-word", textAlign: "center" }}>{file}</Typography>
                                                        </Box></Box>
                                                    </div>
                                                    <Menu
                                                        id="file-option-menu"
                                                        className="file-option-menu"
                                                        anchorEl={anchorEl}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                        open={Optionsopen[prtn_i][fn_i]}
                                                        onClose={(e)=>{e.stopPropagation();handleOptionsClose(prtn_i,fn_i)}}
                                                        sx={{".MuiMenu-paper":{
                                                            backgroundColor:"var(--pallate-blue-gray-700, #334155)"
                                                        }}}
                                                        MenuListProps={{
                                                            'aria-labelledby': 'file-option-button',
                                                            'style':{
                                                                color:"var(--light-text-inverse-text, #FFF)",
                                                                borderRadius:"4px",
                                                                width:"138px"
                                                            }
                                                        }}
                                                    >
                                                        <MenuItem sx={{p:"12px,16px",borderBottom:"1px solid var(--pallate-gray-500, #6B7280)"}}  onClick={(e)=>{handleRenameOption(e,prtn_i,fn_i,project_name,file)}}>Edit</MenuItem>
                                                        <MenuItem onClick={(e)=>{e.stopPropagation();handleDeleteOption(project_name,file,prtn_i,fn_i)}}>Remove</MenuItem>
                                                    </Menu>
                                                </div>
                                                
                                            ))
                                        )) : null
                                    }
                                    {data === 'vizualization' && <>

                                        {saved_data.length > 0 ? (sort === false ? saved_data.sort() : saved_data.reverse()).filter((project) => {
                                            console.log(saved_data)
                                            return search === '' ? saved_data : project.file_name.toLowerCase().includes(search.toLowerCase())
                                        }).map((chats) => (
                                            chats.project_name===projectfile&&<div className="recent-file" onClick={() => openChart(chats, projectfile)}
        //                                     onTouchStart={handleTouchStart}
        // onTouchMove={handleTouchMove}
        // onTouchEnd={handleTouchEnd}
                                            >
                                                <div className="chart-box">
                                                    <img src={barImage} className="char-image" />
                                                </div>
                                                <div className="chart-detail">
                                                                           <Box className="file-name" >
                                                    <Typography sx={{ fontFamily: "inter", fontSize: "12px", fontStyle: "normal", fontWeight: "600", lineHeight: "16px", color: "#FFF", width: "100px", display: "flex", flexWrap: "wrap", wordBreak: "break-word", textAlign: "center", maxWidth: "129px", width: "100%" }}>{chats.file_name}</Typography>
                                                    </Box>
                                                    <Typography sx={{ fontFamily: "inter", fontSize: "10px", fontStyle: "normal", fontWeight: "400", lineHeight: "16px", color: "var(--pallate-blue-gray-300, #CBD5E1)", display: "flex", flexWrap: "wrap", wordBreak: "break-word", textAlign: "left", maxWidth: "140px", width: "100%" }}>Created on:{new Date(chats.created_on).getDate() + "," + new Date(chats.created_on).getMonth() + "," + ((new Date(chats.created_on).getFullYear()) % 100)}</Typography>
                                                </div>
                                            </div>
                                        )) : null}


                                    </>}

                                </div>
                            </div>
                            {data === "file" && <button className="save-more-mob" onClick={() => navigate('/new-project/'+projectfile)}>
                                <div className="pluss">
                                    <img className="plus-icon-mob" src={plusIcon} />
                                </div>
                                <Typography sx={{ fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "20px", color: "#FFF"}}>Add Data Source</Typography>

                            </button>}
                        </div>
                    </div>
                </div>





            }
        </>

    )
}