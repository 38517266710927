import { Box, Typography, Button, IconButton, Tooltip } from "@mui/material"
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import DownloadIcon from '@mui/icons-material/Download';
import { useState, useRef, useEffect } from "react";
import CustomAxios from "../utils/CustomAxios"
import { useSelector } from "react-redux";
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { useDispatch} from 'react-redux';
import {  updateSideBarActiveTab } from "../redux/executiveReducer"

export const EstateMaster=()=>{
    const dispatch = useDispatch()
    const [records,setRecords] = useState([])
    const [newestate, setNewEstate] = useState(false)
    const [estateid, setEstatid] = useState('')
    const [estatename, setEstatename] = useState('')
    const [managername, setManagername] = useState('')
    const [adress1, setAdress1] = useState('')
    const [adress2, setAdress2] = useState('')
    const [adress3, setAdress3] = useState('')
    const [adress4, setAdress4] = useState('')
    const [state, setState] = useState(null)
    const [contribution, setContribution] = useState('')
    const [fax, setFax] = useState('')
    const [tel, setTel] = useState('')
    const [tel2, setTel2] = useState('')
    const [email, setEmail] = useState('')
    const [grouphc, setGrouphc] = useState('')
    const [others, setOthers] = useState('')
    const [update, setUpdate] = useState(false)
    const [success, setSucces] = useState(false)
    const [selectedestate, setSelectedEstate] = useState()
    const [states, setStates] = useState([])
    const [estatePageNo,setEstatePageNo] = useState(1)
    const [pageNo,setPageNo] = useState(1)
    const [errors, setErrors] = useState({})
    let error = {
        estname:"",
        managername:"",
        address:""
    }
    useEffect(()=>{
        const getMaster= async ()=>{
            const response = await CustomAxios.get('oasis/form_details/')
            console.log(response.data)
            setRecords(response.data.estateList)
            const states = response.data.stateList.map((state) =>state.statename)
            setStates(states)
        }
        dispatch(updateSideBarActiveTab('MRE'))
        getMaster()
    },[])

    const clearform = (e) => {
        e.preventDefault()
        setUpdate(false)
        setNewEstate(false)
        setEstatid('')
        setEstatename('')
        setManagername('')
        setFax('')
        setTel('')
        setTel2('')
        setEmail('')
        setContribution('')
        setAdress1('')
        setAdress2('')
        setAdress3('')
        setAdress4('')
        setState('')
        setGrouphc('')
        setOthers('')
    }
    useEffect(()=>{
        if (estateid==""){
            setSelectedEstate([])

        }
    },[estateid])

    useEffect(()=>{
        if(success==true){
         setTimeout(() => {
             setSucces(false);
         }, 5000);
        }
 },[success])

    const SelectedEstate = async (rowdata) =>{
        let response
        console.log(rowdata)
        if (estateid!==""){
            
        await CustomAxios.get(`oasis/master_records/?estateid=${parseInt(estateid)}&param=estate`).then((response)=>setSelectedEstate([response.data.data]))}
        else{
            setEstatid(rowdata.estateid)
            response = await CustomAxios.get(`oasis/master_records/?estateid=${parseInt(rowdata.estateid)}&param=estate`)
            setUpdate(true)
            setNewEstate(true)
            setEstatename(rowdata.estatename?.trim())
            setManagername(rowdata.manager?.trim())
            setFax(rowdata.fax?.trim())
            setTel(rowdata.tel?.trim())
            setTel2(rowdata.tel2?.trim())
            setEmail(rowdata.email?.trim())
            setContribution(rowdata.contribution?.trim())
            setAdress1(rowdata.add1?.trim())
            setAdress2(rowdata.add2?.trim())
            setAdress3(rowdata.add3?.trim())
            setAdress4(rowdata.add4?.trim())
            setState(rowdata.state?.trim())
            setGrouphc(rowdata.group_hc?.trim())
            setOthers(rowdata.others?.trim())

        }
        // console.log(response.data)
    }

    const handleSubmit = async (e) =>{
        e.preventDefault()
        let payload
        let error = {}
        if (estatename==""){
            error.estname = "Estate name is required"
            setErrors(error)
        }
        if (managername==""){
            console.log("manager error")
            error.managername = "Manager name is required"
            setErrors(error)
        }
        if (adress1 == "" || adress2==""){
            error.address = "Atleast two lines of address is required"
            setErrors(error)
        }
        if (update==true){
        payload = {
            estateid:estateid,
            estatename:estatename,
            state:state??null,
            manager:managername,
            add1:adress1,
            add2:adress2,
            add3:adress3??null,
            add4:adress4??null,
            contribution:contribution??null,
            tel:tel??null,
            tel2:tel2??null,
            fax:fax??null,
            email:email??null,
            group_hc:grouphc??null,
            others:others??null,
            update:update??null,
            param:"estate"
        }}
        else{
            payload = {
                estateid:estateid,
                estatename:estatename,
                state:state??null,
                manager:managername,
                add1:adress1,
                add2:adress2,
                add3:adress3??null,
                add4:adress4??null,
                contribution:contribution??null,
                tel:tel??null,
                tel2:tel2??null,
                fax:fax??null,
                email:email??null,
                group_hc:grouphc??null,
                others:others??null,
                update:update,
                param:"estate"
            }}
        
        handdleApi(payload, error)

    }

    const handdleApi = async (payload, error) =>{
        if (isEmpty(error) === true){
            setErrors({})
            console.log("here")
        const response = await CustomAxios.post('oasis/master_records/', payload)
            if (response.data?.message=="Success!"){
                setSucces(true)
                setEstatid(response.data.id)
            }
        }

    }

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(records);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'estate');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    };

    const isEmpty = (obj) => {
        console.log(obj)
        return Object.keys(obj).length === 0;
      };

    const handleEnter = (e) => {
        e.preventDefault()
        if(e.key == "Enter"){
            SelectedEstate()
        }
       }
    const handlePage = (e) => {
        if(estateid!==""){
            let count = estatePageNo+1
            setEstatePageNo(count)
        }else{
            let count = pageNo+1
            setPageNo(count)
        }
    }
    // const paginatorLeft = () => {
    //     return (
    //             <Button onClick={(e)=>handlePage(e) } sx={{width:"289px"}}>Load more</Button>
    //     );
    // };
    // const paginatorRight = () => {
    //     return (
    //         <Box sx={{width:"289px"}}></Box>
    //     );
    // };

    const [filters, setFilters] = useState({
        estateid:{value:null, matchMode:FilterMatchMode.CONTAINS},
        estatename:{value:null, matchMode:FilterMatchMode.CONTAINS}
    })

    const selectEstate = (rowData, rowIndex)=>{
        return(<Tooltip title="View & Edit" arrow><IconButton  onClick={()=>SelectedEstate(rowData)}><ModeEditIcon/></IconButton></Tooltip>)
    }

    const [estateColumn, setEstateColumn] = useState([
        {field:"estateid", header:"Estate ID", visible:true, style:{width:"100px"}, filter:true},
        {field:"estatename", header:"Estate Name", visible:true, style:{width:"200px"}, filter:true},
        {field:"state", header:"State", visible:true, style:{width:"100px"}},
        {field:"contribution", header:"Contribution", visible:true, style:{width:"100px"}},
        {field:"manager", header:"Manager", visible:true, style:{width:"100px"}},
        { field:"estateid",header:"Actions", visible:true,body:selectEstate ,style:{width:"100px"}}
    ])

    const header = (
        <div className="flex align-items-center justify-content-end gap-2" style={{display:"flex", justifyContent:"flex-end"}}>
            <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:"600",lineHeight:"28px",color:"#1E293B"}}>Download Estate Master</Typography>
            <Button sx={{display:"flex", justifyContent:"flex-end" }} type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" ><DownloadIcon sx={{ color: "#64748B", fontSize: "24px" }} /></Button>
        </div>
    );

    console.log(isEmpty(errors))
    return(
        <Box className="fulldiv" style={{ width: "100%", height: "calc(100vh - 64px)", marginTop:"60px" }} >
            {newestate==false?<Box>
                <Box sx={{ display:"flex",flexDirection:"column",gap:"20px",height: {sm:'initial',padding: "0px 20px 0px 20px"},width:"100%" }}>
                     <Box sx={{top:"72px",left:"304px",padding:"8px 0px 8px 0px",display:"flex",justifyContent:"space-between",borderBottom:"1px solid #CBD5E1",alignItems:"center",height:"84px"}}>
                         <Box sx={{maxWidth:"200px",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                            <Box sx={{width:"32px",height:"32px"}}>
                                    <LocationCityOutlinedIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                             </Box>
                     <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:"#1E293B"}}>Estate</Typography>
                </Box>
                <Box sx={{display:"flex", flexDirection:"row", gap:"24px"}}>
                <Box className="p-input-icon-left" sx={{display:"flex",gap:"24px"}}>
                            <i className="pi pi-search" style={{color:"#64748B",zIndex:"2"}}/>
                            <InputText value={estateid} placeholder="Estate ID" onKeyUp={(e)=>handleEnter(e)} onChange={(e) => setEstatid(e.target.value)} style={{height:"48px",width:"400px",fontSize:"16px",color:"#64748B"}}/>
                </Box>
                <Button onClick={(e)=>{
                    clearform(e)
                    setNewEstate(true)}} variant="contained"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}}><LocationCityOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/> <Typography sx={{color:"#FFFFFF",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Add Estate</Typography></Button>
            </Box>
            </Box>
          </Box>
          <Box sx={{width:"100%"}}>
          <DataTable value={estateid!=""&&selectedestate.length>0?selectedestate:records} header = {header} scrollable style={{ backgroundColor: "#F1F5F9" }} rows={7} dataKey="sn" paginator rowcolumnfinalMembersizeMode="expand" removableSort finalMembersizableColumns filters={filters} filterDisplay="row" emptyMessage="No Bulks found.">
          <Column header="SN" headerStyle={{ width: '50px' }} body={(data, options) => options.rowIndex + 1}></Column>
          {estateColumn.filter(col => col.visible).map((col,ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
          </DataTable>
          </Box>
            </Box>:
            <form onSubmit={(e)=>{
                    handleSubmit(e)}} >
            <Box sx={{position:"relative"}}>
            {success==true&& <Box sx={{maxWidth:"580px",width:"100%",height:"84px",border:"2px solid #288d2c",background:"#DCFCE7",borderRadius:"4px",display:"flex",gap:"16px",justifyContent:"center",alignItems:"center",position:"absolute",top:"34px",left:"32%",zIndex:"2"}}>
                        <IconButton><DoneIcon onClick={()=>setSucces(false)} sx={{width:"24px",height:"24px"}}/></IconButton>
                                <Box>
                                    <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "700", lineHeight: "24px",color:"#16A34A"}}>{update?"Estate updated successfully":"New Estate added successfully"}</Typography>
                                </Box>
                        <IconButton><ClearIcon onClick={()=>setSucces(false)} sx={{width:"24px",height:"24px"}}/></IconButton>
                </Box>}
            <Box sx={{ display:"flex",height:"calc(100vh - 148px)", flexDirection:"column",gap:"20px",height: {sm:'initial',padding: "0px 20px 0px 20px"},width:"100%" }}>
                     <Box sx={{top:"72px",left:"304px",padding:"8px 0px 8px 0px",display:"flex",justifyContent:"space-between",borderBottom:"1px solid #CBD5E1",alignItems:"center",height:"84px"}}>
                         <Box sx={{maxWidth:"200px,",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                            <Box sx={{width:"32px",height:"32px"}}>
                                    <LocationCityOutlinedIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                             </Box>
                     <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:"#1E293B"}}>Estate</Typography>
                </Box>
                </Box>
                </Box>
                <Box sx={{padding:"30px",display:"flex",flexDirection:"column",gap:"24px"}}>
                <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                        <Box sx={{maxWidth:"1228px",width:"100%",display:"flex",justifyContent:"space-between",marginRight:"30px",alignItems:"center"}}>
                                            <Box sx={{maxWidth:"200px",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                                            <Box sx={{width:"32px",height:"32px"}}>
                                                    <LocationCityOutlinedIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                                            </Box>
                                        <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"600",lineHeight:"28px",color:"#1E293B"}}>{update==true?"Edit Estate":"Create Estate"}</Typography>
                                        </Box>
                                          <InputText
                                            value={estateid}
                                            placeholder="Estate ID"
                                            style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                        />
                                </Box>
                              
                            <IconButton onClick={(e)=>{
                                clearform(e)
                                setNewEstate(false)}} > <ClearIcon /></IconButton>
                            </Box>
                            <Box sx={{display:"flex", gap:"24px", width:"100%",  flexWrap:"wrap"}}>
                            <Box sx={{display:"flex", gap:"8px", flexDirection:"column", maxWidth:"600px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Person In Charge <Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                                <InputText
                                                    required
                                                    value={managername}
                                                    placeholder="Enter Person In Charge"
                                                    onChange={(e) => setManagername(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "600px", height:"36px", borderRadius: "4px" }}
                                                    />
                                {errors.managername !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "300", fontSize: "14px" }}>{errors.managername}</Typography>}

                                </Box>
                                <Box sx={{display:"flex", gap:"8px", flexDirection:"column", maxWidth:"600px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Estate Name <Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                                <InputText
                                                    required
                                                    value={estatename}
                                                    placeholder="Enter Estate Name"
                                                    onChange={(e) => setEstatename(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "600px", height:"36px", borderRadius: "4px" }}
                                                    />
                                {errors.estname !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "300", fontSize: "14px" }}>{errors.estname}</Typography>}

                                </Box>

                            </Box>
                            <Box sx={{display:"flex", gap:"24px", width:"100%",  flexWrap:"wrap"}}>
                            <Box sx={{display:"flex", gap:"8px", flexDirection:"column", maxWidth:"1232px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Address <Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                <InputText
                                value={adress1}
                                onChange={(e) => setAdress1(e.target.value)}
                                style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px"}}
                            />
                            <InputText
                                value={adress2}
                                onChange={(e) => setAdress2(e.target.value)}
                                style={{padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px"}}
                            />
                            <InputText
                                value={adress3}
                                onChange={(e) => setAdress3(e.target.value)}
                                style={{padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px" }}
                            />
                            <InputText
                                value={adress4}
                                onChange={(e) => setAdress4(e.target.value)}
                                style={{padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px" }}
                            />
                            {errors.address !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "300", fontSize: "14px" }}>{errors.address}</Typography>}
                             <Dropdown showClear value={state} onChange={(e) => setState(e.value)} style={{padding: "0px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"8px",height:"36px",fontWeight:"400",lineHeight:"20px",display:"flex",alignItems:"center"}} options={states.sort()} filter
                                        placeholder="Select State" />
                            </Box>
                            </Box>
                            <Box sx={{display:"flex", gap:"24px", width:"100%",  flexWrap:"wrap"}}>
                            <Box sx={{display:"flex", gap:"8px", flexDirection:"column", maxWidth:"600px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Contribution </Typography>
                                                <InputText
                                                    required
                                                    value={contribution}
                                                    placeholder="Enter Contribution"
                                                    onChange={(e) => setContribution(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "600px", height:"36px", borderRadius: "4px" }}
                                                    />

                                </Box>
                                <Box sx={{display:"flex", gap:"8px", flexDirection:"column", maxWidth:"600px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Fax </Typography>
                                                <InputText
                                                    required
                                                    value={fax}
                                                    placeholder="Enter Fax"
                                                    onChange={(e) => setFax(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "600px", height:"36px", borderRadius: "4px" }}
                                                    />

                                </Box>

                            </Box>
                            <Box sx={{display:"flex", gap:"24px", width:"100%",  flexWrap:"wrap"}}>
                            <Box sx={{display:"flex", gap:"8px", flexDirection:"column", maxWidth:"600px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Tel </Typography>
                                                <InputText
                                                    required
                                                    value={tel}
                                                    placeholder="Enter Tel"
                                                    onChange={(e) => setTel(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "600px", height:"36px", borderRadius: "4px" }}
                                                    />

                                </Box>
                                <Box sx={{display:"flex", gap:"8px", flexDirection:"column", maxWidth:"600px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Tel 2 </Typography>
                                                <InputText
                                                    required
                                                    value={tel2}
                                                    placeholder="Enter Tel 2"
                                                    onChange={(e) => setTel2(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "600px", height:"36px", borderRadius: "4px" }}
                                                    />

                                </Box>

                            </Box>
                            <Box sx={{display:"flex", gap:"24px", width:"100%",  flexWrap:"wrap"}}>
                            <Box sx={{display:"flex", gap:"8px", flexDirection:"column", maxWidth:"600px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Email </Typography>
                                                <InputText
                                                    required
                                                    value={email}
                                                    placeholder="Enter Email"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "600px", height:"36px", borderRadius: "4px" }}
                                                    />

                                </Box>
                                <Box sx={{display:"flex", gap:"8px", flexDirection:"column", maxWidth:"600px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Group / Holding City </Typography>
                                                <InputText
                                                    required
                                                    value={grouphc}
                                                    placeholder="Enter Group / Holding City"
                                                    onChange={(e) => setGrouphc(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "600px", height:"36px", borderRadius: "4px" }}
                                                    />

                                </Box>

                            </Box>
                            <Box sx={{display:"flex", gap:"24px", width:"100%",  flexWrap:"wrap"}}>
                            <Box sx={{display:"flex", gap:"8px", flexDirection:"column", maxWidth:"1232px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Others </Typography>
                                                <InputText
                                                    required
                                                    value={others}
                                                    placeholder="Enter Others"
                                                    onChange={(e) => setOthers(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "1232px", height:"36px", borderRadius: "4px" }}
                                                    />

                                </Box>

                            </Box>
                </Box>
                <Box sx={{position:"sticky",bottom:0}}>
                    {/* <Box sx={{display:"flex",height:"64px",justifyContent:"space-between",padding:"16px 30px",background:"#E2E8F0"}}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#64748B"}}>Balance <Typography component="span" sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "20px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px",color:"#64748B"}} >RM: {(bulkAmount - Total).toFixed(2)}</Typography></Typography>
                        <Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#64748B"}}>Total Amount <Typography  component="span" sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "20px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px",color:"#288d2c"}}>RM: {Total>0?Total.toFixed(2):0..toFixed(2)}</Typography></Typography>
                 </Box> */}
                 <Box sx={{height:"76px",width:"100%",display:"flex",justifyContent:"flex-end",gap:"24px",padding:"14px 30px",background:"#F1F5F9"}}>
                                    <Box sx={{width:"406px",display:"flex",gap:"24px"}}>
                                    <Button onClick={(e)=>clearform(e)} variant="text"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"transparent", textTransform: "none"}}> <ClearIcon sx={{color:"#64748B"}}/><Typography sx={{color:"rgb(100, 116, 139)",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Clear</Typography></Button>
                                    <button type="submit" variant="contained" style={{border:"none",color:"#FFFFFF", textTransform:"none",width:"204px",height:"48px",borderRadius:"4px",padding:"12px",background:"#288d2c", cursor:"pointer" }} onClick={(event) => handleSubmit(event)}>
                                    <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#FFFFFF"}}>Submit</Typography>
                                  </button>
                                    </Box>
                              
                </Box> 
                 </Box>
            </Box>
            </form>}
        </Box>
    )
}