import React, { useState, useRef } from 'react';
import TextField from "@mui/material/TextField";

const MuiInputAmount = React.forwardRef(({
  type = 'text',
  name,
  label,
  variant = "outlined",
  disabled = false,
  required = false,
  value,
  onChange,
  InputProps,
  onKeyUp,
  onKeyDown,
  onBlur,
  className,
  minRows,
  sx = {},
  style={},
  inputKey,
  amount,
  setAmount,
  setTotalPayableAmount,
  selectedOption,
  selectedLoan,
  // setMasterInfoValidation,
  currentMember,
  memberShareCheck,
  setTransType
  // inputRef
}, ref) => {

  // const inputRef = useRef(null);
  const [lastKey, setLastKey] = useState('');

  // Utility function to format the amount with commas for US currency
  const formatAmount = (value) => {
    // Remove non-numeric characters except for the decimal point
    const [integerPart, decimalPart = ''] = value.split('.');
    
    // Format the integer part with commas
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Ensure that there are always two decimal places
    let formattedDecimalPart = decimalPart;
    if (decimalPart.length === 0 && value.includes('.')) {
      formattedDecimalPart = '00';
    } else if (decimalPart.length === 0) {
      formattedDecimalPart = '';
    } else if (decimalPart.length === 1) {
      formattedDecimalPart = decimalPart + '0';
    } else if (decimalPart.length > 2) {
      formattedDecimalPart = decimalPart.slice(0, 2); // Limit to 2 decimal places
    }
  
    return `${formattedIntegerPart}${formattedDecimalPart ? `.${formattedDecimalPart}` : ''}`;
  };  

  const handleKeyDown = (event) => {
    setLastKey(event.key);
  };

  // Handle input change and formatting
  const handleChange = (e) => {
    const rawValue = e.target.value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters
    const cursorPosition = e.target.selectionStart; // Get cursor position before formatting

    // Calculate the length of the value before formatting (to track the cursor position)
    const prevLength = e.target.value.length;

    // if(lastKey === 'Backspace' || lastKey === 'Delete' && )

    // Apply formatting
    const formattedValue = formatAmount(rawValue);

    // Calculate the difference in length caused by formatting (commas added or removed)
    const newLength = formattedValue.length;
    const diffLength = newLength - prevLength;

    if(e.target.name !== 'insurance' && e.target.name !== 'processingfee' && e.target.name !== 'depositAmt') {
      // Set the formatted value
      setAmount(formattedValue);
      if(inputKey === 'amount2_receipts') {
        rawValue>1200||currentMember.length>0&&currentMember[0].sharebalance>=1200||(memberShareCheck.length>0&&(parseInt(memberShareCheck[0].updatedShareBal)+parseInt(rawValue))>1200)?setTransType(2):setTransType(1)
      } else if(inputKey === 'amount10_loan') {
          let totalAmount;
          if (selectedOption === 'Pen') {
              totalAmount = parseInt(selectedLoan.loanbalanceamount) + parseInt(rawValue);
          } else {
              totalAmount = parseInt(selectedLoan.loanbalanceamount) - parseInt(rawValue);
          }
          setTotalPayableAmount(totalAmount);
      } 
      // else if(e.target.name === 'totalLoanAmount') {
      //     setMasterInfoValidation(null)
      // }
    } else {
      setAmount(prev => { return ({ ...prev, [e.target.name]: formattedValue }) });
    }

    // Adjust the cursor position
    setTimeout(() => {
      if (ref.current && typeof ref.current.setSelectionRange === 'function') {
        let newCursorPosition = cursorPosition + diffLength;
        newCursorPosition = formattedValue.split('.').length > 1 && (newCursorPosition >= newLength - 2) ? (lastKey === 'Backspace' || lastKey === 'Delete') ? newCursorPosition - 1 : newCursorPosition + 1 : newCursorPosition
        ref.current.setSelectionRange(newCursorPosition, newCursorPosition);
      }
    }, 0);
  };

  const defaultStyles = {
    width: "100%",
    backgroundColor: "#FFF",
    fontSize: "14px",
    color: "#101010",
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "black", // For WebKit browsers
    },
    ...sx,
  };

  return (
    <TextField
      inputRef={ref}
      minRows={minRows}
      type={type}
      value={amount}
      onChange={handleChange}
      onKeyUp={onKeyUp}
      onKeyDown={handleKeyDown}
      label={label}
      name={name}
      className={className}
      variant={variant}
      disabled={disabled}
      required={required}
      InputProps={InputProps}
      onBlur={onBlur}
      sx={defaultStyles}
      style={style}
      InputLabelProps={{ shrink: !!value || undefined }}
    />
  );
})

export default MuiInputAmount;