// Sidebar.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { DashboardCustomizeOutlined, ReceiptLongOutlined, ArrowCircleRightOutlined, ArrowCircleDownOutlined } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';

import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useDispatch,useSelector } from 'react-redux';
import { updateToggleBarActiveTab, updateIsExpanded } from "../redux/toggleBarReducer"

const Sidebar = ( {color,isHeaderMenuClicked} ) => {
    const [isExpand, setIsExpand] = useState(false);
    const navigate = useNavigate();
    //   const [activeTab, setActiveTab] = useState(null);

    const active_tab = useSelector((state)=>state.toggleBar.toggleBarActiveTab);

    console.log("Active tab", active_tab);

    const isMobile = useMediaQuery({ maxWidth: 600 }); // Adjust the breakpoint as needed
    const isTablet = useMediaQuery({ query: '(min-width: 600px) and (max-width: 900px)' });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
    const isDesktopLg = useMediaQuery({ query: '(min-width: 1536px)' });

    const dispatch = useDispatch();

    const toggleDrawer = () => {
        setIsExpand(!isExpand);
    };

    useEffect(()=>{
        if(isHeaderMenuClicked === false || isHeaderMenuClicked === undefined){

            if(!color){active_tab=="dashboard"?navigate('/dashboard'):navigate('/transactions')}
        }
    },[])
    const handleTabClick = (tab) => {
        // Handle tab click, e.g., navigate to the corresponding route
        console.log(`Clicked on tab: ${tab}`);
        switch (tab){
            case 'dashboard':
                    dispatch(updateToggleBarActiveTab('dashboard'))
                    navigate('/dashboard')
                break;
            case 'transactions':
                    dispatch(updateToggleBarActiveTab('transactions'))
                    navigate('/transactions')
                break;
        }
    };

    return (
        <Box sx={[isMobile && {flexDirection: 'column'},
            {width:{md:"280px"}, display:{xs:"flex", md:"block"},flexShrink:{md:0}, alignItems:{md:"center"}, backgroundColor:"#F8FAFC",minHeight:{md:"92vh"}}
            ]}>
            {isMobile ? null
                // <Box sx={{padding:"24px 16px", display:"flex", alignItems:"center"}}> 
                //     <ArrowCircleDownOutlined />
                // </Box>
            : 
                <Box sx={{padding:{xs:"12px",md:"24px 16px"}, display:"flex", alignItems:"center"}}>
                    <Box className="fn-h3 fw-500">General Items </Box>
                    <ArrowCircleRightOutlined onClick={toggleDrawer} />
                </Box>
            }
            <Box sx={active_tab=="dashboard"&&!color?{ display: "flex",alignItems:"center",gap:"8px",padding:{xs:"12px 16px"},bgcolor:"var(--pallate-blue-gray-600, #475569)",boxShadow:"4px 3px 12px 0px #94A3B8"} :{display: "flex",alignItems:"center",gap:"8px",padding:{xs:"12px 16px"},cursor:"pointer","&:hover":{background:"#E2E8F0",fontWeight:500}}} onClick={()=>{handleTabClick('dashboard')}}>
                <DashboardCustomizeOutlined sx={{fontSize:"24px",fontWeight:!color?active_tab=="dashboard"?600:400:400,color:!color?active_tab=="dashboard"?"#FFFFFF":"#64748B":"#64748B"}}/>
                <Box sx={[isMobile&&{fontSize:"16px",fontWeight:600,fontFamily: "Inter",lineHeight: "24px"},{fontWeight:!color?active_tab=="dashboard"?600:400:400, color:!color?active_tab=="dashboard"?"#FFFFFF":"#64748B":"#64748B"}]} className="fn-h3 fw-500">Dashboard</Box> 
            </Box>
            <Box sx={active_tab=="transactions"&&!color?{ display: "flex",alignItems:"center",gap:"8px",padding:{xs:"12px 16px"},bgcolor:"var(--pallate-blue-gray-600, #475569)",boxShadow:"4px 3px 12px 0px #94A3B8"} :{display: "flex",alignItems:"center",gap:"8px",padding:{xs:"12px 16px"},cursor:"pointer","&:hover":{background:"#E2E8F0",fontWeight:500}}} onClick={()=>{handleTabClick('transactions')}}>
                <ReceiptLongOutlined sx={{fontSize:"24px",fontWeight:!color?active_tab=="transactions"?600:400:400,color:!color?active_tab=="transactions"?"#FFFFFF":"#64748B":"#64748B" }}/>
                <Box sx={[isMobile&&{fontSize:"16px",fontWeight:600,fontFamily: "Inter",lineHeight: "24px"},{fontWeight:!color?active_tab=="transactions"?600:400:400, color:!color?active_tab=="transactions"?"#FFFFFF":"#64748B":"#64748B"}]} className="fn-h3 fw-500">Transactions</Box>
            </Box>
            
        </Box>
    );
};

export default Sidebar;