import { Box, Stack, Button, Typography, IconButton, Grid } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import ArticleIcon from '@mui/icons-material/Article';
import { Dialog } from "primereact/dialog";
import { useState, useEffect } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import CustomAxios from "../utils/CustomAxios";
import { FilterMatchMode } from 'primereact/api';
import { ProgressBar } from "primereact/progressbar";
import { DataTable } from "primereact/datatable"
import TableChartIcon from '@mui/icons-material/TableChart';
import { Checkbox } from "primereact/checkbox";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DownloadIcon from '@mui/icons-material/Download';
import { Column } from "primereact/column"
import { DatePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import DoneIcon from "@mui/icons-material/Done";

import MuiButton from "../elements/Mui/mui_button";

export function ShareCertificate() {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [certType, setCertType] = useState("New")
  const [transDate, setTransDate] = useState(dayjs())
  const [memIdRange, setMemIdRange] = useState([0, 0])
  const [sortOrder, setSortOrder] = useState("MemberID")
  const [states, setStates] = useState([])
  const [estates, setEstates] = useState([])
  const [postalCodes, setPostalCodes] = useState([])

  const [geography, setGeography] = useState('state')
  const [geographyFilter, setGeographyFilter] = useState("ALL")
  const [geographyFilterArray, setGeographyFilterArray] = useState()

  const [isLoading, setIsLoading] = useState(false)
  const [shareCertList, setShareCertList] = useState([])
  const [seperatedColumn, setSeperatedColumn] = useState([])
  const [selectedColumn, setSelectedColumn] = useState([])
  const [arrangeTable, setArrangeTable] = useState(false)
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filterState, setFilterState] = useState(false)
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [totalRecords, setTotalRecords] = useState(null)
  const [errors, setErrors] = useState({memIdRange:"", transDate:"", existYear:""})
  const [emptyMessage, setEmptyMessage] = useState("No Share Certificates found.")
  const [certStatus, setCertStatus] = useState("G")
  const [distinctYears, setDistinctYears] = useState([])
  const [existYear, setExistYear] = useState("")
  const [issuedDateArr, setIssuedDateArr] = useState([])
  const [issuedDate, setIssuedDate] = useState("")
  const [existDialogOpen, setExistDialogOpen] = useState(false)
  const [ackCertStatus, setAckCertStatus] = useState()
  const [response, setResponse] = useState();
  const [success, setSuccess] = useState(false);
  const [currentCertType, setCurrentCertType] = useState(false)
  const [currentCertStatus, setCurrentCertStatus] = useState(false)

  const geographyArray = [
    { name: "State", code: "state" },
    { name: "Estate", code: "estate" },
    { name: "Postal Code", code: "postal" }
]

  const certStatusArr = [
    { name: "Collected", code: "C" },
    { name: "Not Collected", code: "G" },
    { name: "Surrended", code: "S" },
    { name: "Statutory Declaration", code: "D" },
    { name: "Certificate Issued", code: "certIssued" },
  ]

  const ackCertStatusArr = [
    { name: "Collected", code: "C", visible: true },
    { name: "Surrended", code: "S", visible: false },
    { name: "Statutory Declaration", code: "D", visible: false },
    { name: "Cancelled", code: "X", visible: certStatus === 'G'? false: true},
  ]

  let api_count = 0
  useEffect(() => {
    const fetchData = async () => {
      if (api_count === 0) {
          const [response1, response2] = await Promise.all([CustomAxios.get('oasis/initialData/'), CustomAxios.get('oasis/form_details?type=shareCert')])
          if(response1.data) {
            api_count++
            setPostalCodes(response1.data.postal_codes)
          }
          if(response2.data) {
            setEstates(response2.data.estateList)
            setStates(response2.data.stateList)
            setDistinctYears(response2.data.distinctShareCertYears)
            setIssuedDateArr(response2.data.distinctShareCertDates)
            setExistYear(response2.data.distinctShareCertYears[0])
            setIssuedDate(response2.data.distinctShareCertDates[0])
          }
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    /// onchange of geograpgy we are restting the selected option of geograph array filter
    setGeographyFilter('')
    if (geography == 'state') {
        setGeographyFilterArray(states.map(state => state.statename))
    }
    else if (geography == 'estate') {
        setGeographyFilterArray(estates.map(estate => estate.estatename))
    }
    else if (geography == 'postal') {
        setGeographyFilterArray(postalCodes)
    }

}, [states, geography])

const handleSubmit = async(e) => {
    
    e.preventDefault()
    
    console.log("Trans Date: ", transDate)

    if (!parseInt(memIdRange[0]) || !parseInt(memIdRange[1]) || parseInt(memIdRange[0]) > parseInt(memIdRange[1]) || (certType === 'New'?!transDate || transDate.$d == "Invalid Date": (!transDate || transDate.$d == "Invalid Date") || (!existYear || existYear.$d == "Invalid Date"))) {
      if(parseInt(memIdRange[0]) > parseInt(memIdRange[1]))
        setErrors(prev => ({...prev, memIdRange: "Membership Range Start Id should be smaller than or equal to End Id"}))
      else if(!parseInt(memIdRange[0]) || !parseInt(memIdRange[1]))
        setErrors(prev => ({...prev, memIdRange: "Please enter valid membership range"}))
      else
        setErrors(prev => ({...prev, memIdRange: ""}))
      if(!transDate || transDate.$d == "Invalid Date")
        setErrors(prev => ({...prev, transDate: !transDate?"Please choose a trans date":"Invalid Date"}))
      else
        setErrors(prev => ({...prev, transDate: ""}))
      if(!existYear || existYear.$d == "Invalid Date")
        setErrors(prev => ({...prev, existYear: !existYear?"Please choose a year":"Invalid Date"}))
      else
        setErrors(prev => ({...prev, existYear: ""}))

      return
    }
    
    setDialogOpen(false)
    setCurrentCertType(certType)
    setIsLoading(true)
   
    const payload = {
      certType: certType,
      memIdRange: memIdRange,
      state: geography == 'state' ? geographyFilter!=="ALL"?geographyFilter:"" : "",
      estate: geography == 'estate' ? geographyFilter!=="ALL"?geographyFilter:"" : "",
      postal_code: geography == 'postal' ? geographyFilter!=="ALL"?geographyFilter:"" : "",
      sortOrder: sortOrder
    };

    if(certType === 'New') {

      // const currentDateTime = new Date()
      // let isSameDate = transDate.getFullYear() === currentDateTime.getUTCFullYear() && transDate.getMonth() + 1 === currentDateTime.getUTCMonth() + 1 && transDate.getDate() === currentDateTime.getUTCDate()
      // // Extract the components
      // let year = transDate.getFullYear();
      // let month = (transDate.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns 0-based month
      // let day = transDate.getDate().toString().padStart(2, '0');
      // let hours = isSameDate?currentDateTime.getUTCHours().toString().padStart(2, '0'):'00'
      // let minutes = isSameDate?currentDateTime.getUTCMinutes().toString().padStart(2, '0'):'00'
      // let seconds = isSameDate?currentDateTime.getUTCSeconds().toString().padStart(2, '0'):'00'
      // let milliseconds = isSameDate?currentDateTime.getUTCMilliseconds().toString().padStart(3, '0'):'000'

      // // Format the date as %Y-%m-%dT%H:%M:%S.%f
      // let formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;

      const currentDateTime = new Date()
      const formattedDate = transDate?.set('hour', currentDateTime.getUTCHours().toString().padStart(2, '0')).set('minute', currentDateTime.getUTCMinutes().toString().padStart(2, '0')).set('second', currentDateTime.getUTCSeconds().toString().padStart(2, '0')).set('millisecond', currentDateTime.getUTCMilliseconds().toString().padStart(3, '0'));
      console.log("Formatted Trans Date: ", transDate)
      console.log("Formatted Date: ", formattedDate)

      payload['transDate'] = formattedDate?.format("YYYY-MM-DDTHH:mm:ss.SSS")

      setColumnVisible([
        { field: "memberid", header: "Member ID", visible: true, filterField: "memberid", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, datatype: "numeric" },
        { field: "certId", header: "Certificate ID", visible: true, filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, filterField: "certId", datatype: "numeric" },
        { field: "membername", header: "Member Name", visible: true, filterField: "membername", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter },
        { field: "photo", header: "Photo", visible: true, filterField: "photo", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "sharebalance", header: "Share Balance", visible: true, filterField: "sharebalance", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "dueAmt", header: "Due Amount", visible: true, filterField: "dueAmt", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "oldicno", header: "Old IC No.", visible: true, filterField: "oldicno", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "newicno", header: "New IC No.", visible: true, filterField: "newicno", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "printStatus", header: "Print Status", visible: true, filterField: "printStatus", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "state", header: "State", visible: true, filterField: "state", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
      ])
    } else {
      payload['existYear'] = existYear
      payload['certStatus'] = certStatus
      payload['issuedDate'] = issuedDate.split("/").reverse().join("-")

      setColumnVisible([
        { field: "memberid", header: "Member ID", visible: true, filterField: "memberid", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, datatype: "numeric" },
        { field: "certId", header: "Certificate ID", visible: true, filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, filterField: "certId", datatype: "numeric" },
        { field: "membername", header: "Member Name", visible: true, filterField: "membername", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter },
        { field: "newicno", header: "New IC No.", visible: true, filterField: "newicno", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "oldicno", header: "Old IC No.", visible: true, filterField: "oldicno", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "issueddate", header: "Issued Date", visible: true, filterField: "issueddate", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter },
        { field: "collecteddate", header: "Collected Date", visible: true, filterField: "collecteddate", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter },
        { field: "surrendeddate", header: "Surrended Date", visible: true, filterField: "surrendeddate", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter },
        { field: "statdecldate", header: "Stat Decl Date", visible: true, filterField: "statdecldate", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter }
      ])
      setCurrentCertStatus(certStatus);
    }

    const response = await CustomAxios.post('oasis/share_certificate/', payload)
    // console.log("Response: ", response.data.shareCertList)
    if(response.data.shareCertList!="No Records Found for this Criteria") {
      setShareCertList(response.data.shareCertList)
    } else {
        setShareCertList([])
        setEmptyMessage(response.data.shareCertList)
    }
    
    setIsLoading(false)
    
    setSelectedCustomers([])
    setTransDate(dayjs())
    setMemIdRange([0, 0])
    setSortOrder("MemberID")
    setGeography('state')
    setErrors(({memIdRange:"", transDate:"", existYear:""}))
    setResponse()
}

useEffect(() => {
  if (success == true) {
    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  }
}, [success]);

const handleUpdateCertStatus = async (e) => {
  
  e.preventDefault()

  console.log("Trans Date: ",transDate)

  const currentDateTime = new Date()
  const formattedDate = dayjs(transDate)?.set('hour', currentDateTime.getUTCHours().toString().padStart(2, '0')).set('minute', currentDateTime.getUTCMinutes().toString().padStart(2, '0')).set('second', currentDateTime.getUTCSeconds().toString().padStart(2, '0')).set('millisecond', currentDateTime.getUTCMilliseconds().toString().padStart(3, '0'));
  console.log("Formatted Date: ", formattedDate?.format("YYYY-MM-DDTHH:mm:ss.SSS"))
     
  const payload = {
    'ackCertStatus': ackCertStatus,
    'transDate': formattedDate?.format("YYYY-MM-DDTHH:mm:ss.SSS"),
    'certList': selectedCustomers
  }
  const response = await CustomAxios.post('oasis/update_share_certificate/', payload)
  setResponse(response.data)
  setSuccess(true)
  setExistDialogOpen(false)
  console.log("Response: ", response.data)
  console.log("Response: ", response.data.message == "Certificate Status Updated Successfully")

  if (response.data) {
    let updatedShareCertList, updatedDateObj, dateKey = (ackCertStatus === 'C'?"collecteddate":ackCertStatus === 'S'?"surrendeddate":ackCertStatus === 'D'&&"statdecldate")
      if(selectedCustomers.length !== shareCertList.length){
          updatedShareCertList = shareCertList.map(shareCert => {
             let selectedCustomer = selectedCustomers.filter(selectedCustomer => selectedCustomer.memberid==shareCert.memberid)
             if(selectedCustomer.length>0) {

                updatedDateObj = {...shareCert}
                updatedDateObj[dateKey] = !shareCert[dateKey]?`${formattedDate?.format("YYYY-MM-DDTHH:mm:ss")}Z`: shareCert[dateKey]
                updatedDateObj['certificatestatus'] = ackCertStatus

                return updatedDateObj
             }
             else
                return shareCert
        })
      }
      else {
          updatedShareCertList = shareCertList.map(shareCert => {

            updatedDateObj = {...shareCert}
            updatedDateObj[dateKey] = !shareCert[dateKey]?`${formattedDate?.format("YYYY-MM-DDTHH:mm:ss")}Z`: shareCert[dateKey]
            updatedDateObj['certificatestatus'] = ackCertStatus

            return updatedDateObj
          })
      }
      setShareCertList(updatedShareCertList)
      setSelectedCustomers([])
  }
}

const CustomFilter = (options) => {

  return (
      <InputText style={{ width: "182px", height: "36px" }} placeholder="search" value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value,)} />
  );
};

const [columnVisible, setColumnVisible] = useState([
  { field: "memberid", header: "Member ID", visible: true, filterField: "memberid", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, datatype: "numeric" },
  { field: "certId", header: "Certificate ID", visible: true, filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, filterField: "certId", datatype: "numeric" },
  { field: "membername", header: "Member Name", visible: true, filterField: "membername", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter },
  { field: "photo", header: "Photo", visible: true, filterField: "photo", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
  { field: "sharebalance", header: "Share Balance", visible: true, filterField: "sharebalance", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter, body: e => parseFloat(e.sharebalance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') },
  { field: "dueAmt", header: "Due Amount", visible: true, filterField: "dueAmt", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter, body: e => parseFloat(e.dueAmt).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') },
  { field: "oldicno", header: "Old IC No.", visible: true, filterField: "oldicno", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
  { field: "newicno", header: "New IC No.", visible: true, filterField: "newicno", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
  { field: "printStatus", header: "Print Status", visible: true, filterField: "printStatus", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
  { field: "state", header: "State", visible: true, filterField: "state", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
])

const [filters, setFilters] = useState(() => {
  let filter_obj = {}
  filter_obj['global'] = { value: null, matchMode: FilterMatchMode.CONTAINS }
  columnVisible.map((obj) => {
      filter_obj[obj.field] = { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  return filter_obj
});

const onColumnChange = (e) => {
  let column = [...columnVisible]
  let _selectedCategories = [...seperatedColumn];
  if (e.checked) {
      //console.log(e.value)
      let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = true; return item })
      //console.log(column)
      deselect = [...column, ...deselect];
      deselect = [...new Set(deselect)]
      //console.log(deselect)
      setSelectedColumn(deselect)
      _selectedCategories.push(e.value);
  }
  else {
      //console.log(e.value)
      let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = false; return item })
      //console.log(column)
      deselect = [...column, ...deselect];
      deselect = [...new Set(deselect)]
      //console.log(deselect)
      setSelectedColumn(deselect)
      _selectedCategories = _selectedCategories.filter(category => category.header !== e.value.header);


  }
  setSeperatedColumn(_selectedCategories)

};
const applyFilter = () => {
  let columns = [...columnVisible]

  setColumnVisible(selectedColumn)
  setArrangeTable(false)
}

const clearFilter = () => {

  let filter_obj = {}
  filter_obj['global'] = { value: null, matchMode: FilterMatchMode.CONTAINS }
  columnVisible.map((obj) => {
      filter_obj[obj.field] = { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  setFilters(filter_obj)
  setGlobalFilterValue('')
  setFilterState(false)
}

const globalFilterFields = columnVisible.map((obj) => { return obj.field })

const onGlobalFilterChange = (e) => {
  const value = e.target.value;
  let _filters = { ...filters };

  _filters['global'].value = value;
  setFilters(_filters);
  setGlobalFilterValue(value);
};

const onSelectAllChange = (event) => {
  const selectAll = event.checked;

  if (selectAll) {

      setSelectAll(true);
      setSelectedCustomers(shareCertList);

  } else {
      setSelectAll(false);
      setSelectedCustomers([]);
  }
};
const onSelectionChange = (event) => {
  const value = (response?.message === "Certificate Status Updated Successfully" ? event.value.filter(member => member.certificatestatus !== ackCertStatus) : event.value)
  // console.log(value)
  setSelectedCustomers(value);
  //console.log(selectedCustomers)
  setSelectAll(value.length === totalRecords);
};

const printShareCertificate = async () => {
  try {
      const username = localStorage.getItem('username')
      // Fetch the PDF receipt
      const response = await CustomAxios.post(`oasis/print_share_certificate/${username}`,selectedCustomers,{ responseType: 'blob' });
      if (response.data) {
        let updatedShareCertList
          if(selectedCustomers.length !== shareCertList.length) {
              updatedShareCertList = shareCertList.map(shareCert => {
                 let selectedCustomer = selectedCustomers.filter(selectedCustomer => selectedCustomer.memberid==shareCert.memberid)
                 if(selectedCustomer.length>0)
                    return {...shareCert, printStatus:'Y'}
                 else
                    return shareCert
            })
          }
          else {
              updatedShareCertList = shareCertList.map(shareCert => {
                return {...shareCert, printStatus:'Y'}
              })
          }
          setShareCertList(updatedShareCertList)
          setSelectedCustomers([])
          // Create a URL for the PDF blob
          // console.log("Created", response.data)
          const fileURL = URL.createObjectURL(response.data);

          // Open the PDF in a new window/tab
          const win = window.open(fileURL, '_blank');

          // Optionally, automatically trigger the print dialog in the new window
          win.onload = () => {
              win.focus();
              win.print();
          };
      }
  } catch (error) {
      console.error("Error fetching the share certificate: ", error);
  }
};

const header = (
  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
  <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box>

          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText style={{ width: "655px", height: "36px" }} placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} />
          </span>
      </Box>
      <Box sx={{ color: "#64748B", width: "178px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "16px", alignSelf: "end", cursor: "pointer", textTransform: "none", gap: "8px" }} onClick={clearFilter}>
          <ClearAllIcon sx={{ fontSize: "24px", color: "#CBD5E1" }} />
          <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#CBD5E1" }}></Typography>
          Clear Filter
      </Box>
  </Box>
  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "16px", alignSelf: 'end' }}>
       {currentCertType === 'New' && <Button type="button" disabled={selectedCustomers.length===0 || shareCertList.length === 0} severity="success" rounded onClick={printShareCertificate} data-pr-tooltip="XLS" >Download Certificate</Button>}
       {currentCertType === 'Existing' && <Button type="button" disabled={selectedCustomers.length===0 || shareCertList.length === 0 || currentCertStatus === 'S' || currentCertStatus === 'D' || currentCertStatus === 'certIssued'} severity="success" rounded onClick={() => setExistDialogOpen(true)} data-pr-tooltip="XLS" >Update Certificate Status</Button>}
      <Box sx={{ padding: "12px" }}> {filterState ? <FilterAltOffIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(false)} /> : <FilterAltIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(true)} />}</Box>
      <Box sx={{ padding: "12px" }}><TableChartIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => { setArrangeTable(true); setSeperatedColumn(columnVisible) }} /> </Box>

  </Box>
</Box>
);

  const isMobile = useMediaQuery({ maxWidth: 600 })
  return (
    <Box sx={{ backgroundColor: isMobile && "#F8FAFC", height: isMobile ? "100vh" : "calc(100vh - 72px)", width: "100%", overflow: "auto",marginTop:"60px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", height: { sm: 'initial', padding: "0px 20px 0px 20px" }, width: "100%" }}>
        <Box sx={{ top: "72px", left: "304px", padding: "8px 0px 8px 0px", display: "flex", justifyContent: "space-between", borderBottom: "1px solid #CBD5E1", alignItems: "center", height: "84px" }}>
          <Box sx={{ height: "48px", padding: "8px 0px 8px 0px", gap: "12px", display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "32px", height: "32px" }}>
              <ArticleIcon sx={{ width: "32px", height: "32px", color: "#288d2c" }} />
            </Box>
            <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontWeight: "600", lineHeight: "28px", color: "#101010", width: "100%" }}>Share Certificate {currentCertType && "(" + (currentCertType) + ")"}</Typography>
          </Box>
          {response?.message == "Certificate Status Updated Successfully" &&
          <Box
            sx={{
              padding: "30px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                height: "100%",
              }}
            >
              {success == true && (
                <Box
                  sx={{
                    maxWidth: "580px",
                    width: "100%",
                    height: "84px",
                    border: "2px solid #E7EFE9",
                    background: "#DCFCE7",
                    borderRadius: "4px",
                    display: "flex",
                    gap: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: "-24px",
                    zIndex: "2",
                  }}
                >
                  <IconButton>
                    <DoneIcon
                      onClick={() => setSuccess(false)}
                      sx={{ width: "24px", height: "24px" }}
                    />
                  </IconButton>
                  <Box>
                    <Typography
                      sx={{
                        alignSelf: "stretch",
                        width: "100%",
                        fontFamily: "inter",
                        fontSize: "16px",
                        fontStyle: "inter",
                        fontWeight: "700",
                        lineHeight: "24px",
                        color: "#16A34A",
                      }}
                    >
                      {ackCertStatus === 'C'?"Certificates Set as Collected!": ackCertStatus === 'S'?"Certificates Surrended!" : ackCertStatus === 'D' && "Certificates Missing! Statutory Declaration Done!"}
                    </Typography>
                  </Box>
                  <IconButton>
                    <ClearIcon
                      onClick={() => setSuccess(false)}
                      sx={{ width: "24px", height: "24px" }}
                    />
                  </IconButton>
                </Box>
              )}
            </Grid>
          </Box>}
          <Box sx={{ display: "flex", gap: "20px", width:"330px", justifyContent: "flex-end" }}>
            <MuiButton
              variant="contained"
              onClick={() => setDialogOpen(true)} 
              value="Generate Share Certificate"
              btnsx={{
                width: "240px",
              }}
            />
              {/* <Button 
              onClick={() => setDialogOpen(true)} 
              variant="contained" 
              sx={{ width: "240px",height: "48px", borderRadius: "4px", padding: "12px", display: "flex", gap: "8px", background: "#288d2c", textTransform: "none" }}>
                <Typography sx={{ color: "#FFFFFF", fontSize: "16px", fontWeight: "400", lineHeight: "24px", fontFamily: "inter" }}>Generate Share Certificate</Typography>
                </Button> */}
          </Box>
        </Box>
      </Box>
                {isLoading ?
                <Stack rowGap="10px" sx={{ width: "100%", height: "87vh", alignItems: "center", justifyContent: "center" }}>
                    <Typography>Please Wait</Typography>
                    <ProgressBar mode="indeterminate" style={{ width: "25%", height: '6px' }} />
                </Stack>
                :
                <>
                    <Box sx={{ height: "calc(100vh-84px)" }}>

                        <Box className="card">
                            <DataTable value={shareCertList} header={header} rows={15} style={{ backgroundColor: "#F1F5F9", position: "relative" }} scrollable removableSort filters={filters} paginator filterDisplay={filterState ? "row" : ""} columnResizeMode="expand" resizableColumns
                                emptyMessage={emptyMessage} selection={selectedCustomers} selectionMode="checkbox" onSelectionChange={onSelectionChange} selectAll={selectAll} filterIcon={false} onSelectAllChange={onSelectAllChange} globalFilterFields={globalFilterFields} >
                                <Column styleClass="custom-column-style" selectionMode="multiple" headerStyle={{ width: "100px", background: "#F1F5F9" }} frozen />
                                <Column header="SN" headerStyle={{ width: '500px' }} body={(data, options) => options.rowIndex + 1} frozen></Column>
                                {columnVisible.filter(col => col.visible).map(col => <Column className="custom-column-style" style={{ width: "500px" }} field={col.field} header={col.header} body={col.body} alignFrozen="left" filter={col.filter} sortable dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                            </DataTable>
                        </Box>
                    </Box>
                    <Dialog visible={arrangeTable} style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#F8FAFC", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={true} closable={false} resizable={false} >
                        <Box style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#F8FAFC", padding: "30px 30px 0px 30px", borderRadius: "0px", display: "flex", flexDirection: "column", gap: "24px", }}>
                            <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F8FAFC" }}>
                                <TableChartIcon sx={{ fontSize: "24px", color: "#288d2c" }} />
                                <Typography sx={{ fontSize: "18px", fontWeight: 600, lineHeight: "28px" }}>Table Column Configure</Typography>
                            </Box>
                            <Box>


                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText style={{ width: "461px", height: "36px" }} placeholder="Search" onChange={onGlobalFilterChange} />
                                </span>

                            </Box>
                            <Box sx={{ height: "461px", overflowY: "scroll", display: "flex", flexDirection: "column", gap: "20px" }}>

                                {columnVisible.map((column, ind) => {
                                    return (

                                        <div key={column.header} style={{ gap: "8px", display: "flex", alignItems: "center" }}>
                                            <Checkbox inputId={column.header} value={column} onChange={onColumnChange} disabled={column.header == "Member #" || column.header == "MemberName"} checked={column.visible} />
                                            <label htmlFor={column.header} className="ml-2">
                                                {column.header}
                                            </label>
                                        </div>

                                    );
                                })}

                            </Box>
                            <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F1F5F9", paddingTop: "24px", paddingBottom: "24px", justifyContent: "space-between", marginLeft: "-30px", marginRight: "-30px", }}>

                                <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#22c54e" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "138px", height: "48px", marginLeft: "30px" }} startIcon={<CheckCircleOutlineIcon />} onClick={applyFilter} >Apply</Button>
                                <Button variant="outlined" sx={{ borderColor: "#64748B", color: "#64748B", "&:hover": { borderColor: "#64748B" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", marginRight: "30px" }} startIcon={<ClearIcon sx={{ color: "#64748B", fontSize: "24px" }} />} onClick={() => setArrangeTable(false)}>Clear</Button>


                            </Box>
                        </Box>
                    </Dialog >
                </>
            }
      <Dialog
        visible={dialogOpen}
        style={{ width: "50%", height: certType === 'New'?errors.memIdRange && errors.transDate? "50%" : errors.memIdRange || errors.transDate?"47%":"43%": errors.memIdRange && errors.transDate? "50%" : "48%", position: "relative" }}
        onHide={() => setDialogOpen(false)}
      >
        <Box>
        <form>
          <Box sx={{ padding: "5px",display: 'flex', flexDirection: 'column', gap: '25px', border: '1px solid black', margin: '5px' }}>
            <Typography sx={{ color: "black", fontWeight: "600" }}>
              Criteria to Process / View the Cert.
            </Typography>
            <Box sx={{ width: "100%", display: "flex", gap: "20px", alignItems: "center", justifyContent: "space-between", }}>
              <Box sx={{ display: "flex", gap: "20px",  }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                  <RadioButton
                    style={{ display: "flex", alignItems: "center" }}
                    inputId="type1"
                    name="certType"
                    value="New"
                  onChange={(e) => setCertType(e.target.value)}
                  checked={certType === "New"}
                  required
                  />
                  <label htmlFor="type1" className="ml-2">
                    NEW Certificate
                  </label>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "2px" }}
                  >
                    <RadioButton
                      style={{ display: "flex", alignItems: "center" }}
                      inputId="type2"
                      name="certType"
                      value="Existing"
                    onChange={(e) => setCertType(e.target.value)}
                    checked={certType === "Existing"}
                    required
                    />
                    <label htmlFor="type2" className="ml-2">
                      View existing
                    </label>
                    {certType=='Existing' &&<><Dropdown value={certStatus} onChange={(e) => setCertStatus(e.value)} style={{ display: "flex", alignItems: "center", width:"230px", margin: "0 10px" }} options={certStatusArr} optionLabel="name" optionValue="code"
                     />
                    {certStatus=='certIssued' && <>On<Dropdown value={issuedDate} onChange={(e) => setIssuedDate(e.value)} style={{ display: "flex", alignItems: "center", width: '15 0px', margin: "0 10px"}} options={issuedDateArr}/></>}
                </>}
                  </Box>
                </Box>
                <Box>
                </Box>
              </Box>
              {/* <Box sx={{ display: "flex",flexDirection:'column' }}>
              </Box>
              <Box
                sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Typography>Trans Date</Typography>
                <Calendar
                  required
                  value={transDate}
                  onChange={(e) => setTransDate(e.target.value)}
                  dateFormat="dd-mm-yy"
                  placeholder="dd-mm-yyyy"
                  style={{
                    height: "36px",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                  width: "180px",
                }}
              >
                <DatePicker
                  label="TransDate"
                  value={transDate}
                  onChange={(e) => setTransDate(e)}
                  disableFuture
                  format="DD-MM-YYYY"
                  sx={{ backgroundColor: "white" }}
                />
              {errors.transDate !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "300", fontSize: "14px" }}>{errors.transDate}</Typography>}
              </Box>
            </Box>

            {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      alignSelf: "stretch",
                      fontFamily: "inter",
                      fontSize: "16px",
                      fontStyle: "inter",
                      fontWeight: "600",
                      lineHeight: "24px",
                      color: "#101010",
                      width: "150px"
                    }}
                  >
                   Share Balance :
                  </Typography>
                  <InputText
                      type='number'
                      value={shareBalance}
                      onChange={(e) => setShareBalance(e.target.value)}
                      style={{
                        width: "150px",
                        borderRadius: "4px",
                        border: "1px solid var(--Light-Forms-Border, #CBD5E1)",
                        fontSize: "14px",
                      }}
                  />
                </Box> */}
            
            <Box sx={{display: 'flex', gap: "10px", border: '1px solid black', padding: '10px'}}>
            <Box sx={{ display: "flex", gap: "20px", flexDirection: "column", width: "70%" }}>
              <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap: '35px' }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      alignSelf: "stretch",
                      fontFamily: "inter",
                      fontSize: "16px",
                      fontStyle: "inter",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#101010",
                      width: "150px"
                    }}
                  >
                    Membership No :
                  </Typography>
                  <InputText
                      type='number'
                      value={memIdRange[0]}
                      onChange={(e) => setMemIdRange([e.target.value, memIdRange[1]])}
                      style={{
                      width: "150px",
                      borderRadius: "4px",
                      border: "1px solid var(--Light-Forms-Border, #CBD5E1)",
                      fontSize: "14px",
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      alignSelf: "stretch",
                      fontFamily: "inter",
                      fontSize: "16px",
                      fontStyle: "inter",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#101010",
                      paddingRight: "10px",
                    }}
                  >
                    To :
                  </Typography>
                  <InputText
                    type='number'
                    value={memIdRange[1]}
                    onChange={(e) => setMemIdRange([memIdRange[0], e.target.value])}
                    style={{
                      padding: "8px 12px",
                      width: "150px",
                      borderRadius: "4px",
                      border: "1px solid var(--Light-Forms-Border, #CBD5E1)",
                      fontSize: "14px",
                    }}
                  />
                </Box>
              </Box>
              {errors.memIdRange !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "300", fontSize: "14px" }}>{errors.memIdRange}</Typography>}
              <Box sx={{ display: "flex", gap: "8px", flexDirection: "column" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", alignSelf: "start" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Choose any one option :</Typography>

                </Box>
                <Box sx={{ display: "flex", gap: '35px' }}>
                  <Dropdown value={geography} onChange={(e) => setGeography(e.value)} style={{ display: "flex", alignItems: "center" }} options={geographyArray} optionLabel="name" optionValue="code"
                    placeholder="State" />
                  <Dropdown value={geographyFilter} onChange={(e) => setGeographyFilter(e.value)} style={{ display: "flex", alignItems: "center" }} options={geographyFilterArray}
                    placeholder="All" filter />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "20px", flexDirection: "column", width: "30%" }}>
            {certType=='Existing' && <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              Existing Year
              <Dropdown value={existYear} onChange={(e) => setExistYear(e.value)} style={{ display: "flex", alignItems: "center", width: '100px'}} options={distinctYears}/>
              </Box>
              }
              {errors.existYear !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "300", fontSize: "14px" }}>{errors.existYear}</Typography>}
            <Box sx={{ display: "flex", flexDirection: "column", gap: certType=='New'?'10px':'5px', border: '1px solid black', padding: certType=='New'?'10px':'5px', height: '100%' }}>
              <Typography sx={{ color: "black", fontWeight: "600" }}>
                Sort Order
              </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                  <RadioButton
                    style={{ display: "flex", alignItems: "center" }}
                    inputId="type3"
                    name="sortOrder"
                    value="MemberID"
                  onChange={(e) => setSortOrder(e.value)}
                  checked={sortOrder === "MemberID"}
                  />
                  <label htmlFor="type1" className="ml-2">
                    Membership No
                  </label>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "2px" }}
                  >
                    <RadioButton
                      style={{ display: "flex", alignItems: "center" }}
                      inputId="type4"
                      name="sortOrder"
                      value="MemberName"
                      onChange={(e) => setSortOrder(e.value)}
                      checked={sortOrder === "MemberName"}
                    />
                    <label htmlFor="type2" className="ml-2">
                      Name
                    </label>
                  </Box>
                </Box>
              </Box>
            </Box>
            </Box>
          </Box>
          <Box sx={{display:"flex", justifyContent: "center"}}>
            <Button type="submit"  variant="contained" sx={{ marginTop: "5px",height: "32px", borderRadius: "4px", padding: "12px", display: "flex", gap: "8px", background: "#288d2c", textTransform: "none" }} onClick={handleSubmit}>View Share Certificate Details</Button>
          </Box>
        </form>
        </Box>
      </Dialog>

      <Dialog
        visible={existDialogOpen}
        style={{ width: "30%", height: "22%", position: "relative" }}
        onHide={() => setExistDialogOpen(false)}
      >
        <Box>
          <form>
          <Box sx={{ padding: "5px",display: 'flex', flexDirection: 'column', gap: '25px', border: '1px solid black', margin: '5px' }}>
            <Typography sx={{ color: "black", fontWeight: "600" }}>
              Criteria to Update the Cert Status.
            </Typography>
              <Box sx={{ display: "flex", gap: "20px",  }}>
              <Typography
                    sx={{
                      alignSelf: "stretch",
                      fontFamily: "inter",
                      fontSize: "16px",
                      fontStyle: "inter",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#101010",
                      paddingRight: "10px",
                    }}
                  >
                    Certificate Status :
                  </Typography>
                <Dropdown placeholder="Select a status" value={ackCertStatus} onChange={(e) => setAckCertStatus(e.value)} style={{ display: "flex", alignItems: "center",}} options={certStatus === "G"?ackCertStatusArr.filter(status => status.visible):certStatus === "C"?ackCertStatusArr.filter(status => !status.visible):ackCertStatusArr} optionLabel="name" optionValue="code"
                     />
                <Box>
                </Box>
              </Box>
          </Box>
          <Box sx={{display:"flex", justifyContent: "center"}}>
            <Button type="submit"  variant="contained" sx={{ marginTop: "5px", height: "32px", borderRadius: "4px", padding: "12px", display: "flex", gap: "8px", background: "#288d2c", textTransform: "none" }} onClick={handleUpdateCertStatus}>Update Share Certificate Status</Button>
          </Box>
        </form>
          </Box>
      </Dialog>
    </Box>

  )
}