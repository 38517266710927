
import React, { useEffect, useState,useRef } from "react";
import { PrimeReactProvider } from 'primereact/api';
import { Box, Typography, Grid, Select, MenuItem, Button } from "@mui/material";
import { ReceiptLongOutlined } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import '../styles/brandingStyles/branding.css';
import '../styles/pageStyles/dashboard.css';
import ClearIcon from '@mui/icons-material/Clear';
import ShareBalIcon from '../assets/dashboard/share.png';
import LastWithdrawnIcon from '../assets/dashboard/lastWithdrawn.png';
import LastDepositIcon from '../assets/dashboard/lastDeposit.png';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Sidebar from "../components/sidebar";
import { useSelector } from 'react-redux';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import ReactDOMServer from 'react-dom/server';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
// const TableContent=()=>{
//     return(<Box id="memberdetails">
//     
// }
import logo from "../assets/oasis_logo.png";

import Header from '../components/header';

import html2canvas from "html2canvas"
import { Calendar } from "primereact/calendar";
import { LinkHeader } from "../components/linkHeader";
import CustomAxios from "../utils/CustomAxios";
export const Transactions = () => {
    const componentExport=useRef()
    // console.log(localStorage.getItem('role'))
    const role = localStorage.getItem('role')
    const isExpanded = useSelector(state=>state.executive.isExpanded);
    // console.log("isExpanded in dashboard page", isExpanded);
    let userDetails
    let member
    let memberid
    if(role=='member'){
       userDetails=JSON.parse(window.sessionStorage.getItem('member'))
       member= userDetails['member'] 
       memberid = localStorage.getItem('memberid');
    }
    if(role=="staff"||role=="admin"){
        userDetails=JSON.parse(window.sessionStorage.getItem('search_memberid'))
        memberid = userDetails.search_memberid
    }
    // console.log(member,userDetails)
    const [memberId, setMemberId] = useState(null);
    const [memberName, setMemberName] = useState(null);
    const [memberBalance, setMemberBalance] = useState([]);
    const [transDetails, setTransDetails] = useState([]);
    const [filterState, setFilterState] = useState(false)
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [selectedOption, setSelectedOption] = useState('overall_transactions');
    const [ transType, setTransType] = useState('share_transactions')
    const [dates, setDates] = useState(null);
    const [payloadDr, setPayloadDr] = useState(false);
    const [loanDetails,setLoanDetails]=useState()
    // const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selected,setSelected]=useState([])
    const onRowSelect = (event) => {
       console.log(event.data)
       let loantrans=transDetails
       // console.log(loantrans)
       let loan_data=loantrans.data
       // console.log(loan_data)
       let filtered=loan_data.filter(item=>item.loanno==event.data.loanid)
       console.log(filtered)
       setSelected(filtered)
//    setTransDetails((pre)=>{return {...pre,data:filtered}})
    };

    const onRowUnselect = (event) => {
        setSelected([])
    };
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })
    const globalFilterFields =["transdetailid","narration","bankname","chequeno","transtotalamount"]
    useEffect(() => {
        //console.log("getting memberid from ls--------------------------", localStorage.getItem('memberid'));

        // testing member id hardcoded
        // const memberid = 487;
        // {
        //     "memberid":487,
        //     "transtype":"share_transactions",
        //     "year":""
        // }

        const payload1 = {
            memberid: memberid,
            // hardcoded transtype and year **** NEED TO WORK AROUND LATER ****
            transtype:"share_sub_yearly",
            year:2023
        }


        CustomAxios.post(`oasis/member_dashboard/`, payload1)
          .then(response => {
            // Handle API response as needed
            //console.log('API Response:', response);
            const responseData = response.data;
            console.log("ResponseData", responseData);
            setMemberName(localStorage.getItem('username'));     
            setMemberBalance(responseData);
            // setResetLinkSent(true)
            })
            .catch(error => {
                console.error('Error:', error);
            });



        const payload = {
            memberid: memberid,
            transtype: transType,
            year: ""
            // memberid: 31309,
            // password: password
        };

        // Send data to the API endpoint using Axios (replace with your actual API endpoint)
        CustomAxios.post(`oasis/transaction/`, payload)
        //axios.post('http://127.0.0.1:8000/oasis/transaction/', payload)
          .then(response => {
            // Handle API response as needed
            //console.log('API Response:', response);
            const responseData = response.data;
            setLoanDetails(response.data.loan)
            //console.log("ResponseData", responseData);
            
                setTransDetails(responseData);
            
            // setResetLinkSent(true)
            })
            .catch(error => {
                console.error('Error:', error);
            });

    },[])

    const handleTransType = (transType) => {
        //console.log("transtype------", transType);
        setTransType(transType);
        // const memberid = localStorage.getItem('memberid');
        const payload = {
            memberid: memberid,
            transtype: transType,
            year: "",
            // memberid: 31309,
            // password: password
        }
        let dateFormate
        if(dates && dates.length>0){
            dateFormate = dates.map(item => {
                const originalDate = new Date(item);
                const formattedDate = `${originalDate.getFullYear() }-${originalDate.getMonth() + 1}-${originalDate.getDate()}`;
                const [day, month, year] = formattedDate.split("/")
                return formattedDate
            })
        }
        const payloadDate = {
            memberid: memberid,
            transtype: transType,
            year: "",
            dr:dateFormate,
            // memberid: 31309,
            // password: password
        };

        // Send data to the API endpoint using Axios (replace with your actual API endpoint)
        CustomAxios.post(`oasis/transaction/`, payloadDr==true&&selectedOption=="by_date"?payloadDate:payload)
        //axios.post('http://127.0.0.1:8000/oasis/transaction/', payloadDr==true&&selectedOption=="by_date"?payloadDate:payload)
                  .then(response => {
            // Handle API response as needed
            //console.log('API Response inside dropdown function------------:', response);
            const responseData = response.data;
            setLoanDetails(response.data.loan)
            //console.log("ResponseData-----------", responseData);
            
                setTransDetails(responseData);
            
            setDates(null) 
            setPayloadDr(false)
            // setResetLinkSent(true)
            })
            .catch(error => {
                console.error('Error:', error);
            });
            
           
    }

   useEffect(()=>{
    if(dates!==null){
        setPayloadDr(true)
    if(!dates.includes(null)){
        handleTransType(transType)
    }
   }
   },[dates])
   useEffect(()=>{
    setSelectedOption("overall_transactions")
   },[transType])
    
    const handleDropdownChange = (event) => {
        setSelectedOption(event.target.value);


        // ------------------------------------------------ clean up needed wil do that later ------------------------------------------------
        // const memberid = localStorage.getItem('memberid');
        // const payload = {
        //     memberid: memberid,
        //     transtype: event.target.value,
        //     year: ""
        //     // memberid: 31309,
        //     // password: password
        // };

        // // Send data to the API endpoint using Axios (replace with your actual API endpoint)
        // axios.post('https://stage-oasis.inofii.com/oasis/transaction/', payload)
        //   .then(response => {
        //     // Handle API response as needed
        //     console.log('API Response inside dropdown function------------:', response);
        //     const responseData = response.data;
        //     console.log("ResponseData-----------", responseData);
        //     setTransDetails(responseData);
        //     // setResetLinkSent(true)
        //     })
        //     .catch(error => {
        //         console.error('Error:', error);
        //     });
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        //console.log(_filters)

        _filters['global'].value = value;
        //console.log(_filters)

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const clearFilter = () => {
        setFilters(
            {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            }
        )
        setGlobalFilterValue("")
    }
    const downloadPDF=()=>{
        const pdf = new jsPDF('l', 'mm', 'a4');
        const tableElement = document.querySelector(`#datatable table`)
       
       
        const content={header:"KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391 )",
        subheader:"Member Transaction Details Report",name:member.membername,address: member.add1+","+member.add2+","+member.add3 +","+member.add4,email:member.emailid,mobile:member.mobileno,sex:member.sex=="M"?"Male":"Female" }
        
        // const header=document.querySelector("#header").textContent
        
        // const dataStyles = { textColor: '#333333', fontStyle: 'normal', lineColor: '#dddddd' };
        pdf.autoTable({
            // startY: y,
            // margin: { left: x, right: 10 },
            body: [[{content:content.header,colSpan: 3,style:{fontStyle: 'bold' , fontWeight:"600",border:"none", textColor: '#000'}}],[{content:content.subheader,colSpan: 3,style:{fontStyle: 'normal' , fontWeight:"600",border:"none"}}]],
            // theme:"grid",
            columnStyles: { 0: { cellWidth: 180, fillColor:false } },
            styles: {fontSize: 20}
        });
       
        pdf.autoTable({
            // startY: y,
            // margin: { left: x, right: 10 },
        
            body: [
            [{content:"Name:"+content.name,colSpan: 3,style:{fontStyle: 'normal' , fontWeight:"600",border:"none"}}],
            [{content:"Address:"+content.address,colSpan: 3,style:{fontStyle: 'normal' , fontWeight:"600",border:"none"}}],
            [{content:"Mobile.No:"+content.mobile,colSpan: 3,style:{fontStyle: 'normal' , fontWeight:"600",border:"none"}}],
            [{content:"E-mail:"+content.email,colSpan: 3,style:{fontStyle: 'normal' , fontWeight:"600",border:"none"}}],
            [{content:"Sex:"+content.sex,colSpan: 3,style:{fontStyle: 'normal' , fontWeight:"600",border:"none"}}]

        ],
            // theme:"grid",
            columnStyles: { 0: { cellWidth: 180, fillColor:false } },
            styles: {fontSize: 16}
        });
        
        pdf.autoTable({
            
            html:tableElement,
            useCss:true,
            theme:"grid",
        })
        pdf.save("Member Transaction.pdf")
    }

    const dateOfJoinBody=(rowData)=>{
        console.log(rowData)

        const originalDate = new Date(rowData.transdate);
        const year = originalDate.getFullYear();
        const month = String(originalDate.getMonth() + 1).padStart(2, '0');
        const day = String(originalDate.getDate()).padStart(2, '0');
        const hours = String(originalDate.getHours()).padStart(2, '0');
        const minutes = String(originalDate.getMinutes()).padStart(2, '0');
        const formattedDate = `${day}-${month}-${year}`;
        // const formattedDate = `${month} ${day},${year}`

        return formattedDate;
    }
    const loanStartDate=(rowData)=>{
        //console.log(rowData)

        const originalDate = new Date(rowData.loanstartdate);
        const year = originalDate.getFullYear();
        const month = String(originalDate.getMonth() + 1).padStart(2, '0');
        const day = String(originalDate.getDate()).padStart(2, '0');
        const hours = String(originalDate.getHours()).padStart(2, '0');
        const minutes = String(originalDate.getMinutes()).padStart(2, '0');
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    }
    const loanEndDate=(rowData)=>{
        //console.log(rowData)

        const originalDate = new Date(rowData.loanexpirydate);
        const year = originalDate.getFullYear();
        const month = String(originalDate.getMonth() + 1).padStart(2, '0');
        const day = String(originalDate.getDate()).padStart(2, '0');
        const hours = String(originalDate.getHours()).padStart(2, '0');
        const minutes = String(originalDate.getMinutes()).padStart(2, '0');
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;

        return formattedDate;
    }
    const numFormatpayment_in = (rowData) =>{
        console.log(rowData)
         if(rowData.payment_in){
            return parseInt(rowData.payment_in).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
         }
    }
    const numFormatpayment_out = (rowData) =>{
         if(rowData.payment_out){
            return parseInt(rowData.payment_out).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
         }
    }
    const numFormatbalance=(rowData)=>{
        if(rowData.balance){
            return parseInt(rowData.balance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
         }

    }
    const  principle= (rowData) =>{
         if(rowData.principalamount){
            return parseInt( rowData.principalamount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
         }
    }
  
    const interest = (rowData) =>{
        if(rowData.interestamount){
           return parseInt( rowData.interestamount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }
   }
   const total = (rowData) =>{
    if(rowData.amountbeforededuction){
       return parseInt( rowData.amountbeforededuction).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
}
const mthInst = (rowData) =>{
    if(rowData.emiamount){
       return parseInt( rowData.emiamount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
}
const  loanBalance= (rowData) =>{
    if(rowData.loanbalanceamount){
       return parseInt( rowData.loanbalanceamount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
}

    const isMobile = useMediaQuery({ maxWidth: 600 }); // Adjust the breakpoint as needed
    // const isTablet = useMediaQuery({ query: '(min-width: 600px) and (max-width: 900px)' });
    // const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    // const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
    // const isDesktopLg = useMediaQuery({ query: '(min-width: 1536px)' });
    const handleFetchAndPrintReceipt = async () => {
        try {
            const payload = {
                transtype:transType=="share_transactions"?"share":transType=="subscription_transactions"?"subscription":null
            };
            // URL to your Django view that generates the PDF
            const receiptUrl = `oasis/member_ledger_statement/${memberid}/`;

            // Fetch the PDF receipt
            const response = await CustomAxios.post(receiptUrl,payload,{ responseType: 'blob' });
            if (response.data) {
                // Create a URL for the PDF blob
                console.log("Created", response.data)
                const fileURL = URL.createObjectURL(response.data);

                // Open the PDF in a new window/tab
                const win = window.open(fileURL, '_blank');

                // Optionally, automatically trigger the print dialog in the new window
                win.onload = () => {
                    win.focus();
                    win.print();
                };
            }
        } catch (error) {
            console.error("Error fetching the receipt: ", error);
        }
    };
    return(
        <PrimeReactProvider>
            <Box sx={[isMobile&&{p:"32px 0px 58px 0px"}]}>
                {role=="member"&&<Header title="Koperasi" />}
                {(role=="staff"||role=="admin")&&<LinkHeader title="Koperasi" />}
                <Box sx={{display: {xs:"block",md:"flex"}}} >
                    {!isMobile&&<Sidebar />}
                    <Box sx={{px:2, width:"100%",overflow:"auto"}}>
                        <Box sx={{ display: "flex",alignItems:"center",mb:3,my:{xs:"20px"},gap:"8px"  }} >
                            <ReceiptLongOutlined sx={{width:{xs:"24px",md:"40px"},height:{xs:"24px",md:"40px"},color:"#288d2c" }}/>
                            <Box className="fn-h2 fw-500" sx={{fontSize : {xs:"24px",md:"30px"}}}>Transactions</Box> 
                        </Box>
                        <Grid container spacing={{xs:0,sm:1.5}} className="scroll" sx={[isMobile&&{flexWrap:"nowrap",overflow:"auto"}]}>
                            <Grid item xs={6} sm={2} sx={{padding:"20px", cursor:"pointer", borderBottom:`2px solid ${transType === 'share_transactions' ? '#288d2c' : '#E2E8F0'}`}} onClick={() => handleTransType("share_transactions")} >
                                <Box sx={[isMobile&&{fontSize:"14px",lineHeight:"20px"},{color:"#64748B"}]} className="pgh-heading fw-700">
                                    Share Balance
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={2} sx={{padding:"20px", cursor:"pointer", borderBottom:`2px solid ${transType === 'subscription_transactions' ? '#288d2c' : '#E2E8F0'}`}} onClick={() => handleTransType("subscription_transactions")} >
                                <Box sx={[isMobile&&{fontSize:"14px",lineHeight:"20px"},{color:"#64748B"}]} className="pgh-heading fw-700">
                                    Subscription Balance
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={2} sx={{padding:"20px", cursor:"pointer", borderBottom:`2px solid ${transType === 'loan_transactions' ? '#288d2c' : '#E2E8F0'}`}} onClick={() => handleTransType("loan_transactions")}>
                                <Box sx={[isMobile&&{fontSize:"14px",lineHeight:"20px"},{color:"#64748B"}]} className="pgh-heading fw-700">
                                    Loan Balance
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={2} sx={{padding:"20px", cursor:"pointer", borderBottom:`2px solid ${transType === 'specific_transactions' ? '#288d2c' : '#E2E8F0'}`}} onClick={() => handleTransType("specific_transactions")}>
                                <Box sx={[isMobile&&{fontSize:"14px",lineHeight:"20px"},{color:"#64748B"}]} className="pgh-heading fw-700">
                                    Specific Deposit
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={2} sx={{padding:"20px", cursor:"pointer", borderBottom:`2px solid ${transType === 'bonus_transactions' ? '#288d2c' : '#E2E8F0'}`}} onClick={() => handleTransType("bonus_transactions")}>
                                <Box sx={[isMobile&&{fontSize:"14px",lineHeight:"20px"},{color:"#64748B"}]} className="pgh-heading fw-700">
                                    Bonus
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={2} sx={{padding:"20px", cursor:"pointer", borderBottom:`2px solid ${transType === 'dr_transactions' ? '#288d2c' : '#E2E8F0'}`}} onClick={() => handleTransType("dr_transactions")}>
                                <Box sx={[isMobile&&{fontSize:"14px",lineHeight:"20px"},{color:"#64748B"}]} className="pgh-heading fw-700">
                                    Download Receipts
                                </Box>
                            </Grid>
                        </Grid>
                        
                        
                        <Grid container spacing={1.5}>
                            <Grid item xs={12} md={4} lg={2.4}>
                                <Box sx={{padding:"12px"}}>
                                    { transType === 'share_transactions' && <Box sx={{display:"flex", alignItems:"center"}}>
                                        <Box component='img' sx={{width:"68px", height:"68px"}} src={ShareBalIcon} />
                                        <Box>
                                            <Box sx={{pl:1}} className="pgh-heading" >Current Share Balance</Box>
                                            <Box className="fn-h1">
                                                RM {memberBalance.share?parseInt(memberBalance.share).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):(0).toFixed(2)}
                                            </Box>
                                        </Box>
                                    </Box>}
                                    { transType === 'subscription_transactions' && <Box sx={{display:"flex", alignItems:"center"}}>
                                        <Box component='img' sx={{width:"68px", height:"68px"}} src={ShareBalIcon} />
                                        <Box>
                                            <Box sx={{pl:1}} className="pgh-heading" >Current Subscription Balance</Box>
                                            <Box className="fn-h1">
                                                RM {memberBalance.subscription?parseInt(memberBalance.subscription).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):(0).toFixed(2)}
                                            </Box>
                                        </Box>
                                    </Box>}
                                    {  transType === 'loan_transactions' && <Box sx={{display:"flex", alignItems:"center"}}>
                                        <Box component='img' sx={{width:"68px", height:"68px"}} src={ShareBalIcon} />
                                        <Box>
                                            <Box sx={{pl:1}} className="pgh-heading" >Current Loan Balance</Box>
                                            <Box className="fn-h1">
                                                RM {memberBalance.loanbalance?parseInt(memberBalance.loanbalance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):(0).toFixed(2)}
                                            </Box>
                                        </Box>
                                    </Box>}
                                    {  transType === 'specific_transactions' && <Box sx={{display:"flex", alignItems:"center"}}>
                                        <Box component='img' sx={{width:"68px", height:"68px"}} src={ShareBalIcon} />
                                        <Box>
                                            <Box sx={{pl:1}} className="pgh-heading" >Current Specific Deposit Balance</Box>
                                            <Box className="fn-h1">
                                                RM {memberBalance.specific_deposit?parseInt(memberBalance.specific_deposit).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):(0).toFixed(2)}
                                            </Box>
                                        </Box>
                                    </Box>}
                                    {  transType === 'bonus_transactions' && <Box sx={{display:"flex", alignItems:"center"}}>
                                        <Box component='img' sx={{width:"68px", height:"68px"}} src={ShareBalIcon} />
                                        <Box>
                                            <Box sx={{pl:1}} className="pgh-heading" >Current Bonus Balance</Box>
                                            <Box className="fn-h1">
                                                RM {memberBalance.bonus?parseInt(memberBalance.bonus).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):(0).toFixed(2)}
                                            </Box>
                                        </Box>
                                    </Box>}
                                    {/* {  transType === 'dr_transactions' && <Box sx={{display:"flex", alignItems:"center"}}>
                                        <Box component='img' sx={{width:"68px", height:"68px"}} src={ShareBalIcon} />
                                        <Box>
                                            <Box sx={{pl:1}} className="pgh-heading" >Current dr_transactions Balance</Box>
                                            <Box className="fn-h1">
                                                RM {memberBalance.maturity_date}
                                            </Box>
                                        </Box>
                                    </Box>} */}
                                </Box>
                            </Grid>
                            {/* <Grid item xs={12} md={4} lg={2.4}>
                                <Box sx={{padding:"12px"}}>
                                    <Box sx={{display:"flex", alignItems:"center"}}>
                                        <Box component='img' sx={{width:"68px", height:"68px"}} src={LastWithdrawnIcon} />
                                        <Box>
                                            <Box sx={{pl:1}} className="pgh-heading" >Last withdrawn amount</Box>
                                            <Box className="fn-h1">
                                                RM {memberBalance.share}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4} lg={2.4}>
                                <Box sx={{padding:"12px"}}>
                                    <Box sx={{display:"flex", alignItems:"center"}}>
                                        <Box component='img' sx={{width:"68px", height:"68px"}} src={LastDepositIcon} />
                                        <Box>
                                            <Box sx={{pl:1}} className="pgh-heading" >Last Deposit amount</Box>
                                            <Box className="fn-h1">
                                                RM {memberBalance.share}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid> */}
                        </Grid>
                        {transType === 'loan_transactions' &&<Grid>
                        <DataTable id="datatable" value={loanDetails}  selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)} dataKey="loandetailid"
                    onRowSelect={onRowSelect} onRowUnselect={onRowUnselect}  rows={10} style={{ backgroundColor: "#F1F5F9" }} scrollable scrollHeight="650px" removableSort resizableColumns 
                              >
                            <Column header="SN" headerStyle={{ width: '500px' }} body={(data, options) => options.rowIndex + 1} frozen></Column>
                            <Column field="loanid" sortable header="Loan No" filterField="chequedate" filterPlaceholder="Search" style={{width:"500px"}}/>
                            <Column field="loanstartdate" sortable header="Loan Start" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px"}} body={loanStartDate} />
                            <Column field="loanexpirydate" sortable header="Loan End Dt" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px"}} body={loanEndDate} />
                            <Column field="principalamount" sortable header="Principal (RM)" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px"}} body={principle} />
                            <Column field="interestamount" sortable header="Interest (%)" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px"}} body={interest} />
                            <Column field="amountbeforededuction" sortable header="# Total Loan Amt (RM)" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px"}} body={total} />
                            <Column field="rateofinterest" sortable header="# intRate (%)" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px"}}/>
                            <Column field="noofinstalment" sortable header="# of install" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px"}}/>
                            <Column field="emiamount" sortable header="# of install Year" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px"}} body={(item)=>item.noofinstalment/12}/>
                            <Column field="emiamount" sortable header="Mthly Inst (RM)" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px"}} body={mthInst} />
                            <Column field="loanbalanceamount" sortable header="LoanBalance (RM)" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px"}} body={loanBalance} />
                            {/* <Column  header="Select" body={seleceButton} style={{width:"500px"}}/> */}

                        </DataTable>

                            
                        </Grid>}

                        {/* Table work around */}
                        <Box sx={{ display: "flex",marginTop:"24px", justifyContent: 'space-between'}}>
                        <Box sx={{ display: "flex" }}>
                                {/* commented search field because it is breaking */}
                               {/* <Box>
                                    <span className="p-input-icon-left">
                                        <i className="pi pi-search" />
                                        <InputText style={{ width: "655px", height: "36px" }} placeholder="Search" value={globalFilterValue}  onChange={onGlobalFilterChange} />
                                    </span>
                                </Box>  */}
                                <Box sx={{gap:"20px",display:"flex",flexWrap:"wrap"}}>
                                <Box id="dropdown">
                                    <Select  style={{ width: "315px", height: "36px" ,fontFamily:"inter",fontSize:"16px"}} value={selectedOption} onChange={handleDropdownChange}>
                                        <MenuItem value="overall_transactions" onClick={()=>handleTransType(transType)}>Show All Transaction</MenuItem>
                                        <MenuItem value="by_date">By Date</MenuItem>
                                       {/* <MenuItem value="share_transactions">Share Transactions</MenuItem>
                                        <MenuItem value="subscription_transactions">Subscription Transactions</MenuItem>  */}
                                    </Select>       
                                </Box>
                                      {/* <div className="card flex justify-content-center"> */}
                                {/* <Dropdown
                                className="p-inputgroup"
                                    style={{ width: "315px", height: "36px" ,display:"flex",alignItems:"center",fontFamily:"inter",fontSize:"16px"}}
                                    // value={selectedOption}
                                    // options={options}
                                    // onChange={(e) => setSelectedOption(e.value)}
                                    placeholder="By Date"
                                /> */}
                                {/* </div> */}
                              {selectedOption=="by_date"&&<div className="card flex justify-content-center">
                                <Calendar className="p-inputgroup" value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" style={{ width: "315px", height: "36px" }} readOnlyInput placeholder='mm/dd/yyyy - mm/dd/yyyy' showIcon />
                            </div>}
                            </Box>
                            </Box>
                            <Box id="secondBox"  sx={{display:"flex",gap:"20px"}}>
                            {(role=="staff"||role=="admin")&&(transType=="share_transactions"||transType=="subscription_transactions")&&<Button
                               type="submit"
                                variant="contained"
                                onClick={handleFetchAndPrintReceipt}
                                 sx={{ width: '250px' }} // Adjust width as needed
                                  >
                                  Print Member Ledger Statement
                              </Button>}
                                <Box sx={{ padding: "12px" }}><DownloadIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={downloadPDF} /> </Box>
                                {/* <Box sx={{ padding: "12px" }}><TableChartIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => { setArrangeTable(true); setSeperatedColumn(columnVisible) }} /> </Box> */}
                            </Box>
                        </Box>

                        <Box sx={{ marginTop: "24px", }} id="memberdetails" ref={componentExport}>
                        
                        <div className="card">
                            <DataTable id="datatable" value={transType!='loan_transactions' ? transDetails.data:selected} paginator filters={filters} rows={10} style={{ backgroundColor: "#F1F5F9" }} scrollable scrollHeight="650px" removableSort resizableColumns 
                               emptyMessage="No customers found." globalFilterFields={globalFilterFields}>
                                 <Column field="transdate" sortable header="Transaction Date" filterField="transdate" body={dateOfJoinBody} filterPlaceholder="Search" style={{width:"500px"}}/>
                                <Column field="transdetailid" sortable header="Transactions #" filterField="transdetailid"  filterPlaceholder="Search" style={{width:"500px"}}/>
                                <Column field="narration" sortable header="Narration" filterField="narration"  filterPlaceholder="Search" style={{width:"500px"}}/>
                                <Column field="chequeno" sortable header="Cheque No/Type" filterField="chequeno"  filterPlaceholder="Search" style={{width:"500px"}}/>
                                <Column field="bankname" sortable header="Bank Name" filterField="bankname"  filterPlaceholder="Search" style={{width:"500px"}}/>
                                {/* <Column field="transamount" sortable header="Transactions Amount" filterField="transamount"  filterPlaceholder="Search" style={{width:"500px"}}/> */}
                                <Column field="payment_in" sortable header="Deposit" filterField="payment_in"  filterPlaceholder="Search" style={{width:"500px",textAlign:"right"}} body={numFormatpayment_in}/>
                                <Column field="payment_out" sortable header="Withdrawal" filterField="payment_out"  filterPlaceholder="Search" style={{width:"500px",textAlign:"right"}} body={numFormatpayment_out}/>
                               { transType === 'loan_transactions'? null: <Column field="balance" sortable header="Balance" filterField="balance"  filterPlaceholder="Search" style={{width:"500px",textAlign:"right"}} body={numFormatbalance}/>}
                                {/* <Column field="Payment" sortable header="Payment" filterField="Payment"  filterPlaceholder="Search" style={{width:"500px"}}/> */}
                                {/* <Column field="transdetailid" sortable header="Transactions #" filterField="transdetailid" filter filterPlaceholder="Search" />
                                <Column field="transdetailid" sortable header="Transactions #" filterField="transdetailid" filter filterPlaceholder="Search" />
                                <Column field="transdetailid" sortable header="Transactions #" filterField="transdetailid" filter filterPlaceholder="Search" />
                                <Column field="transdetailid" sortable header="Transactions #" filterField="transdetailid" filter filterPlaceholder="Search" />
                                <Column field="transdetailid" sortable header="Transactions #" filterField="transdetailid" filter filterPlaceholder="Search" /> */}
                            </DataTable>
                        </div>
                        </Box>

                        {/* end of table work around */}
                        
                    </Box>
                </Box>
            </Box>
        </PrimeReactProvider>
    )
}